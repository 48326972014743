/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { PaletteType, Theme as MuiTheme } from "@material-ui/core";
import { useTheme as useMuiTheme } from "@material-ui/core/styles";
import type { ThemeOptions as MuiThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import muiCreateMuiTheme from "@material-ui/core/styles/createMuiTheme";
import type { Palette as MuiPalette, PaletteOptions as MuiPaletteOptions } from "@material-ui/core/styles/createPalette";
import { colorScales, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { OctopusTheme as DesignSystemTheme } from "@octopusdeploy/design-system-tokens";
import type { OctopusTheme } from "~/components/Theme";
interface StatusColors {
    danger: string;
    info: string;
}
interface ExtraPaletteOptions {
    status: StatusColors;
}
export type OctopusPaletteOptions = ExtraPaletteOptions & MuiPaletteOptions;
function createLightPalette(theme: OctopusTheme): OctopusPaletteOptions {
    return {
        type: "light",
        primary: { main: colorScales.blue["500"] },
        secondary: {
            main: colorScales.blue["500"],
            contrastText: colorScales.white,
        },
        text: {
            // The most important text.
            primary: theme.primaryText, //This controls the remove icon on some chips
            // Secondary text.
            secondary: theme.secondaryText,
            // Disabled text have even lower visual prominence.
            disabled: theme.disabledButtonText,
            // Text hints.
            hint: theme.ternaryText,
        },
        background: {
            paper: theme.paper1,
            default: theme.paper1,
        },
        divider: theme.dividerLight,
        // The colors used to style the action elements.
        action: {
            // The color of an active action like an icon button.
            active: theme.primaryText,
            // The color of an hovered action.
            hover: theme.hover,
            hoverOpacity: 0.08,
            // The color of a selected action.
            selected: theme.hover,
            // The color of a disabled action.
            disabled: theme.disabledButtonText,
            // The background color of a disabled action.
            disabledBackground: theme.disabledBackground,
        },
        error: {
            main: theme.danger,
        },
        status: {
            danger: theme.alert,
            info: theme.info,
        },
    };
}
function createDarkPalette(theme: OctopusTheme): OctopusPaletteOptions {
    return {
        type: "dark",
        primary: { main: colorScales.blue["400"] },
        secondary: {
            main: colorScales.blue["400"],
        },
        background: {
            paper: theme.paper1,
            default: theme.paper1,
        },
        text: {
            // The most important text.
            primary: theme.primaryText, //This controls the remove icon on some chips
            // Secondary text.
            secondary: theme.secondaryText,
            // Disabled text have even lower visual prominence.
            disabled: theme.disabledButtonText,
            // Text hints.
            hint: theme.ternaryText,
        },
        divider: theme.dividerLight,
        error: {
            main: theme.danger,
        },
        status: {
            danger: theme.alert,
            info: theme.info,
        },
    };
}
export const zIndexDialogs = 900;
export const zIndexPopovers = 800;
export const zIndexStickyTopPanel = 11;
export const zIndexStickies = 10;
function createMuiTheme(type: PaletteType, legacyTheme: OctopusTheme, designSystemTheme: DesignSystemTheme): MuiTheme {
    const palette = type === "dark" ? createDarkPalette(legacyTheme) : createLightPalette(legacyTheme);
    const options: MuiThemeOptions = {
        palette,
        overrides: {
            MuiMenu: {
                paper: {
                    backgroundColor: legacyTheme.paper2,
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: legacyTheme.teritaryDivider,
                },
            },
            MuiPopover: {
                paper: {
                    boxShadow: designSystemTheme.shadowMedium,
                },
            },
            MuiDialog: {
                paper: {
                    boxShadow: designSystemTheme.shadowLarge,
                },
            },
            MuiInputBase: {
                root: {
                    color: themeTokens.color.textField.text.primary,
                },
                input: {
                    "&::placeholder": {
                        color: themeTokens.color.textField.text.secondary,
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    color: themeTokens.color.textField.text.secondary,
                    font: text.regular.default.large,
                    "&$focused": {
                        color: themeTokens.color.textField.text.selected,
                    },
                    "&$error": {
                        color: themeTokens.color.textField.text.danger,
                    },
                },
            },
            MuiInputLabel: {
                root: {
                    color: themeTokens.color.textField.text.secondary,
                },
                shrink: {
                    color: themeTokens.color.textField.text.secondary,
                },
            },
            MuiInput: {
                underline: {
                    "&:before": {
                        borderBottomColor: themeTokens.color.textField.border.default,
                    },
                    "&:hover:not($disabled):before": {
                        borderBottomColor: themeTokens.color.textField.border.hover,
                        borderBottomWidth: "2px",
                    },
                    "&:after": {
                        borderBottomColor: themeTokens.color.textField.border.active,
                    },
                    "&$error": {
                        "&:after": {
                            borderBottomColor: themeTokens.color.textField.text.danger,
                        },
                    },
                },
                input: {
                    "&::placeholder": {
                        opacity: 1,
                    },
                },
            },
        },
    };
    return muiCreateMuiTheme(options) as Theme;
}
type ThemePaletteType = PaletteType;
interface OctopusPalette extends MuiPalette {
    status: StatusColors;
}
export interface Theme extends MuiTheme {
    palette: OctopusPalette;
}
export const useTheme = () => {
    return useMuiTheme<Theme>();
};
export default createMuiTheme;
export { createMuiTheme, ThemePaletteType };
