import { logger } from "@octopusdeploy/logging";
import type { ChannelResource, DeploymentProcessResource, EnvironmentResource, GitHubAppConnectionSummary, GitHubRepository, LifecycleResource, NewTriggerResourceTyped, ReleaseResource, ResourcesById, TriggerResourceTyped, } from "@octopusdeploy/octopus-server-client";
import { TriggerGitFilterResource, CreateReleaseActionResource, isExistingTriggerResource, Permission, TriggerActionType, TriggerFilterType, Repository, HasGitPersistenceSettings, IsUsingGitHubAuth } from "@octopusdeploy/octopus-server-client";
import type { TriggerExecutionResource } from "@octopusdeploy/octopus-server-client/src/resources/triggerExecutionResource";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import { useFieldErrors } from "~/components/FieldErrorContext/FieldErrorContext";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import toResourceLookup from "~/utils/toResourceLookup";
import { CreateOrEditGitTriggerForm } from "./CreateOrEditGitTriggerForm";
export type GitTrigger = TriggerResourceTyped<TriggerGitFilterResource, CreateReleaseActionResource> | NewTriggerResourceTyped<TriggerGitFilterResource, CreateReleaseActionResource>;
type CreateOrEditGitTriggerInternalProps = {
    triggerId?: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
};
function CreateOrEditGitTriggerInternal({ triggerId, doBusyTask, busy }: CreateOrEditGitTriggerInternalProps) {
    const projectContext = useProjectContext();
    const [trigger, setTrigger] = useState<GitTrigger | undefined>(undefined);
    const [existingTriggers, setExistingTriggers] = useState<GitTrigger[]>([]);
    const [deploymentProcess, setDeploymentProcess] = useState<DeploymentProcessResource | undefined>(undefined);
    const [channels, setChannels] = useState<ChannelResource[]>([]);
    const [lifecyclesById, setLifecyclesById] = useState<ResourcesById<LifecycleResource>>({});
    const [environmentsById, setEnvironmentsById] = useState<ResourcesById<EnvironmentResource>>({});
    const [projectTriggerLastExecution, setProjectTriggerLastExecution] = useState<TriggerExecutionResource | "never-run" | undefined>(undefined);
    const [lastCreatedRelease, setLastCreatedRelease] = useState<ReleaseResource | undefined>(undefined);
    const [connection, setConnection] = useState<GitHubAppConnectionSummary | undefined>(undefined);
    const [projectRepository, setProjectRepository] = useState<GitHubRepository | undefined>(undefined);
    const errors = useErrors();
    const { getFieldError } = useFieldErrors();
    const { navigate } = useSpaceAwareNavigation();
    const loadLastExecution = async (trigger?: GitTrigger) => {
        let projectTriggerLastExecution: TriggerExecutionResource | "never-run" | undefined = undefined;
        let lastCreatedRelease: ReleaseResource | undefined = undefined;
        if (trigger && isExistingTriggerResource(trigger)) {
            try {
                projectTriggerLastExecution = await repository.ProjectTriggers.getLastExecution(trigger);
                if (projectTriggerLastExecution?.Detail.LastCreatedReleaseId) {
                    lastCreatedRelease = await repository.Releases.get(projectTriggerLastExecution?.Detail.LastCreatedReleaseId);
                }
            }
            catch (error) {
                if (error.StatusCode === 404) {
                    projectTriggerLastExecution = "never-run";
                }
                else {
                    logger.error("Failed to load trigger history");
                }
            }
        }
        setProjectTriggerLastExecution(projectTriggerLastExecution);
        setLastCreatedRelease(lastCreatedRelease);
    };
    const loadInitialData = async () => {
        const project = projectContext.state.model;
        const gitRef = projectContext.state.gitRef;
        const canViewDeploymentProcess = isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" });
        let bestChannelThatIsNotAlreadyInUse: ChannelResource | undefined = undefined;
        if (canViewDeploymentProcess) {
            const existingTriggersPromise = repository.Projects.getTriggers(project, gitRef, 0, Repository.takeAll, TriggerActionType.CreateRelease);
            const deploymentProcessPromise = projectContext.state.projectContextRepository.DeploymentProcesses.getForDefaultBranch();
            const channelsPromise = repository.Projects.getChannels(project);
            const [existingTriggers, deploymentProcess, channels] = await Promise.all([existingTriggersPromise, deploymentProcessPromise, channelsPromise]);
            const allLifecycleIds = channels.Items.map((channel) => channel.LifecycleId ?? project?.LifecycleId);
            const lifecyclesPromise = repository.Lifecycles.previews([...new Set(allLifecycleIds)]);
            const environmentsPromise = repository.Environments.all();
            const [lifecycles, environments] = await Promise.all([lifecyclesPromise, environmentsPromise]);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const existingGitTriggers = existingTriggers.Items.filter((t) => (triggerId === undefined || t.Id !== triggerId) && t.Filter.FilterType === TriggerFilterType.GitFilter) as GitTrigger[];
            const channelsNotAlreadyInUse = channels.Items.filter((c) => !existingGitTriggers.some((t) => t.Action.ChannelId === c.Id));
            bestChannelThatIsNotAlreadyInUse = channelsNotAlreadyInUse.length > 0 ? channelsNotAlreadyInUse.find((c) => c.IsDefault) || channelsNotAlreadyInUse[0] : undefined;
            setExistingTriggers(existingGitTriggers);
            setDeploymentProcess(deploymentProcess);
            setChannels(channels.Items);
            setLifecyclesById(toResourceLookup(lifecycles));
            setEnvironmentsById(toResourceLookup(environments));
        }
        if (HasGitPersistenceSettings(project.PersistenceSettings) && IsUsingGitHubAuth(project.PersistenceSettings.Credentials) && isAllowed({ permission: Permission.GitCredentialView, project: project.Id, tenant: "*" })) {
            const gitPersistenceSettings = project.PersistenceSettings;
            const credentials = project.PersistenceSettings.Credentials;
            const connections = await repository.GitHubApp.getConnections(0, Repository.takeAll);
            const connection = connections.Connections.find((c) => c.Id === credentials.Id);
            if (connection) {
                const repositories = await repository.GitHubApp.getRepositoriesForConnection(connection.Id);
                const projectRepository = repositories.Repositories.find((r) => r.GitUrl === gitPersistenceSettings.Url);
                setProjectRepository(projectRepository);
                setConnection(connection);
            }
        }
        if (triggerId) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const trigger = (await repository.ProjectTriggers.get(triggerId)) as GitTrigger;
            setTrigger(trigger);
            await loadLastExecution(trigger);
        }
        else {
            const triggerFeedResource = new TriggerGitFilterResource([]);
            const triggerActionResource = new CreateReleaseActionResource();
            if (bestChannelThatIsNotAlreadyInUse)
                triggerActionResource.ChannelId = bestChannelThatIsNotAlreadyInUse.Id;
            const newTrigger: NewTriggerResourceTyped<TriggerGitFilterResource, CreateReleaseActionResource> = {
                ProjectId: projectContext.state.model.Id,
                Name: "",
                Description: "",
                IsDisabled: false,
                Filter: triggerFeedResource,
                Action: triggerActionResource,
            };
            setTrigger(newTrigger);
        }
    };
    useRefreshLoop(async () => doBusyTask(async () => {
        await loadLastExecution(trigger);
    }), 10000);
    useDoBusyTaskEffect(doBusyTask, loadInitialData, [triggerId]);
    const saveTrigger = async (trigger: GitTrigger) => {
        await doBusyTask(async () => {
            if (isExistingTriggerResource(trigger)) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const updatedTrigger = (await repository.ProjectTriggers.modify(trigger)) as GitTrigger;
                setTrigger(updatedTrigger);
            }
            else {
                const newTrigger = await repository.ProjectTriggers.create(trigger);
                navigate(links.editReleaseCreationGitTriggerPage.generateUrl({ triggerId: newTrigger.Id, spaceId: project.SpaceId, projectSlug: project.Slug }));
            }
        });
    };
    const enableTrigger = async () => {
        await doBusyTask(async () => {
            if (trigger && isExistingTriggerResource(trigger)) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const updatedTrigger = (await repository.ProjectTriggers.modify({
                    ...trigger,
                    IsDisabled: false,
                })) as GitTrigger;
                setTrigger(updatedTrigger);
            }
        });
    };
    const disableTrigger = async () => {
        await doBusyTask(async () => {
            if (trigger && isExistingTriggerResource(trigger)) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const updatedTrigger = (await repository.ProjectTriggers.modify({
                    ...trigger,
                    IsDisabled: true,
                })) as GitTrigger;
                setTrigger(updatedTrigger);
            }
        });
    };
    const deleteTrigger = async () => {
        await doBusyTask(async () => {
            if (trigger && isExistingTriggerResource(trigger)) {
                await repository.ProjectTriggers.del(trigger);
                navigate(links.deploymentTriggersPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
            }
        });
    };
    const project = projectContext.state.model;
    if (!trigger && errors) {
        return <PaperLayoutVNext busy={busy} errors={errors} title={"Git Repository Trigger"} breadcrumbsItems={[{ label: "Triggers", pageUrl: links.deploymentTriggersPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]}/>;
    }
    if (!trigger || !existingTriggers || !deploymentProcess || !channels || !lifecyclesById || !environmentsById) {
        return null;
    }
    return (<CreateOrEditGitTriggerForm busy={busy} errors={errors} getFieldError={getFieldError} project={projectContext.state.model} trigger={trigger} existingTriggers={existingTriggers} deploymentProcess={deploymentProcess} channels={channels} lifecyclesById={lifecyclesById} environmentsById={environmentsById} projectTriggerLastExecution={projectTriggerLastExecution} lastCreatedRelease={lastCreatedRelease} connection={connection} repository={projectRepository} onSave={saveTrigger} onEnable={enableTrigger} onDisable={disableTrigger} onDelete={deleteTrigger}/>);
}
type CreateOrEditGitTriggerProps = {
    triggerId?: string;
};
export class CreateOrEditGitTrigger extends DataBaseComponent<CreateOrEditGitTriggerProps> {
    constructor(props: CreateOrEditGitTriggerProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <CreateOrEditGitTriggerInternal triggerId={this.props.triggerId} doBusyTask={this.doBusyTask} busy={this.state.busy}/>;
    }
    static displayName = "CreateOrEditGitTrigger";
}
