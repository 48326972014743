import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ProjectsQueryParams } from "./ProjectsPageVNext";
import { ProjectsPageVNextWrapper } from "./ProjectsPageVNext";
interface ProjectsPageProps {
    spaceId: string;
    queryParams: ProjectsQueryParams;
    setQueryParams: QueryParamValuesSetter<ProjectsQueryParams>;
}
function ProjectsPage({ spaceId, queryParams, setQueryParams }: ProjectsPageProps) {
    return <ProjectsPageVNextWrapper spaceId={spaceId} queryParams={queryParams} setQueryParams={setQueryParams}/>;
}
export default ProjectsPage;
