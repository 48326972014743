/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { session, repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import { Navigation } from "~/components/NavigationSidebarLayout";
import { PortalPageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
interface UserProfileLayoutState {
    user: UserResource;
}
export class UserProfileLayout extends BaseComponent<{}, UserProfileLayoutState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            user: null!,
        };
    }
    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }
    async componentDidMount() {
        const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;
        this.setState({ user: user! });
    }
    render() {
        const navLinks = compact([
            Navigation.navItem("My Profile", links.currentUserDetailsPage.generateUrl()),
            Navigation.navItem("My Logins", links.currentUserLoginsPage.generateUrl()),
            Navigation.navItem("My API Keys", links.currentUserApiKeysPage.generateUrl()),
            Navigation.navItem("My Recent Activity", links.currentUserActivityPage.generateUrl(), null!, { permission: Permission.EventView, wildcard: true }),
        ]);
        return (<main>
                <PortalPageLayout navItems={navLinks} content={this.props.children} header={<PortalPageHeaderPrimary title={"My Profile"}/>}/>
            </main>);
    }
    static displayName = "UserProfileLayout";
}
