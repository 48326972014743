import { css } from "@emotion/css";
import { LinearProgress, PageHeaderSecondary } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import type { ReactElement, PropsWithChildren } from "react";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { getErrorsFromError, isErrors } from "~/components/DataBaseComponent/Errors";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { StickyStatus } from "~/components/Sticky/Sticky";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import styles from "./style.module.less";
type BreadcrumbProps = {
    breadcrumbsItems?: BreadcrumbItem[];
    showBreadcrumbBackIcon?: boolean;
};
export interface PaperLayoutProps extends BreadcrumbProps {
    title?: string;
    titleLogo?: JSX.Element;
    busy?: Promise<unknown> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    errors?: Errors | Error[];
    callout?: React.ReactNode;
    className?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    flatStyle?: boolean;
    disableAnimations?: boolean;
    disableHeaderAnimations?: boolean;
    disableSettingPageTitle?: boolean;
    disableScrollToActiveError?: boolean;
    hideErrors?: boolean;
    //TODO: FEF - Remove this property as it is no longer valid
    /**
     * @deprecated disabling sticky header is no longer supported as sticky headers have been removed
     * as part of vertical navigation
     */
    disableStickyHeader?: boolean;
    titleChip?: ReactElement;
    titleComplementaryText?: string;
    titleAccessory?: ReactElement;
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: SimpleMenuItem[];
}
type PaperLayoutInnerProps = PaperLayoutProps;
interface PaperLayoutState {
    stickyState: StickyStatus;
}
export const headerId = "paperlayoutstickyheader";
class PaperLayoutInner extends React.Component<PaperLayoutInnerProps, PaperLayoutState> {
    constructor(props: PaperLayoutInnerProps) {
        super(props);
        this.state = {
            stickyState: 0,
        };
    }
    renderHeader() {
        if (this.props.title || this.isPageActionsAvailable()) {
            return (<div id={headerId} className={styles.headerContainer}>
                    {this.renderBusy()}
                    {this.renderHeaderSecondary()}
                    {this.renderErrors()}
                    <div className={calloutContainerStyles}>{this.props.callout}</div>
                </div>);
        }
        return this.renderBusy();
    }
    isPageActionsAvailable() {
        return this.props.primaryAction || (this.props.pageActions && this.props.pageActions.length !== 0) || (this.props.overflowActions && this.props.overflowActions.length !== 0);
    }
    renderHeaderSecondary() {
        return (<BusyFromPromise promise={this.props.busy}>
                {(busy: boolean) => (<PageHeaderSecondary breadcrumbsItems={this.props.breadcrumbsItems} showBreadcrumbBackIcon={this.props.showBreadcrumbBackIcon} title={this.props.title} titleStatusIcon={this.props.titleLogo} titleChip={this.props.titleChip} titleComplementaryText={this.props.titleComplementaryText} titleAccessory={this.props.titleAccessory} primaryAction={this.props.primaryAction} pageActions={this.props.pageActions} overflowActions={this.props.overflowActions} isStickied={this.state.stickyState === StickyStatus.STATUS_FIXED}/>)}
            </BusyFromPromise>);
    }
    renderBusy() {
        // Note: Don't show the horizontal loading bar on subsequent auto-reloads (it "blips" too quickly and annoys everybody).
        return (<div className={styles.busyFromPromise}>
                <BusyFromPromise promise={this.props.busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy && !this.props.enableLessIntrusiveLoadingIndicator}/>}</BusyFromPromise>
            </div>);
    }
    renderErrors() {
        if (this.props.hideErrors) {
            return null;
        }
        const errors = this.props.errors;
        if (!errors) {
            return null;
        }
        const convertedErrors = isErrors(errors) ? [errors] : errors.map(getErrorsFromError);
        return convertedErrors.map((error, index) => (<ErrorPanel key={index} message={error.message} errors={error.errors} parsedHelpLinks={error.parsedHelpLinks} helpText={error.helpText} helpLink={error.helpLink} statusCode={error.statusCode} scrollToPanel={!this.props.disableScrollToActiveError}/>));
    }
    render() {
        return (<div className={cn(styles.container, this.props.className)}>
                <div className={cn(this.props.flatStyle ? styles.paperContentFlat : styles.paperContent, this.props.fullWidth && styles.paperFullWidth, this.props.innerClassName)}>
                    {this.renderHeader()}
                    <TransitionAnimation disableAnimation={this.props.disableAnimations}>
                        <>{this.props.children}</>
                    </TransitionAnimation>
                </div>
            </div>);
    }
    static displayName = "PaperLayoutInner";
}
function PaperLayout(props: PropsWithChildren<PaperLayoutProps>) {
    return <PaperLayoutInner {...props}/>;
}
const calloutContainerStyles = css({
    backgroundColor: themeTokens.color.background.primary.default,
});
export default PaperLayout;
