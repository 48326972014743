import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { ProjectCreatePullRequestLink } from "../ProjectCurrentBranchDisplay/ProjectCreatePullRequestLink";
import { BranchSelector } from "./BranchSelector";
import type { BranchSelectorVNextProps } from "./BranchSelectorVNext";
import { BranchSelectorVNext } from "./BranchSelectorVNext";
export function PageHeaderBranchSelector() {
    return (<div className={branchSelectorContainer}>
            <BranchSelector />
            <ProjectCreatePullRequestLink />
        </div>);
}
export function PageHeaderBranchSelectorVNext(props: BranchSelectorVNextProps) {
    return (<div className={branchSelectorContainer}>
            <BranchSelectorVNext {...props}/>
            <ProjectCreatePullRequestLink />
        </div>);
}
const branchSelectorContainer = css({
    display: "flex",
    gap: space["16"],
    alignItems: "center",
});
