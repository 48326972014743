import { css } from "@emotion/css";
import { Divider, useSetPageTitlePrimarySegmentEffect } from "@octopusdeploy/design-system-components";
import type { SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { Fragment } from "react";
import * as React from "react";
import type { ReactElement, ReactNode } from "react";
import { PortalPageHeaderPrimary } from "../PageHeaderPrimary";
export interface Level1EmptyStateLayoutProps {
    title: string;
    titleStyles?: string;
    intro: ReactNode;
    actionButtonsAndLinks?: ReactElement[];
    image: ReactElement;
    analyticLocation?: string;
    header?: {
        title: string;
        primaryAction?: PrimaryPageAction;
        actions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    bottomSection?: ReactNode;
}
export function Level1EmptyStateLayout({ title, titleStyles, intro, image, actionButtonsAndLinks, analyticLocation, header, bottomSection }: Level1EmptyStateLayoutProps) {
    useSetPageTitlePrimarySegmentEffect(title);
    return (<AnalyticLinkLocationProvider location={analyticLocation ?? "Onboarding Page"}>
            <div className={containerStyles}>
                {header && <PortalPageHeaderPrimary title={header.title} actions={header.actions} primaryAction={header.primaryAction} overflowActions={header.overflowActions}/>}
                <section className={onboardingContainerStyles}>
                    <div className={contentStyles}>
                        <div className={topSectionStyles}>
                            <div className={topSectionContentStyles}>
                                <h1 className={titleStyles ?? defaultTitleStyles}>{title}</h1>
                                {intro}
                                {actionButtonsAndLinks && actionButtonsAndLinks.length !== 0 && (<div className={actionsContainerStyles}>
                                        {actionButtonsAndLinks.map((item, index) => (<Fragment key={index}>{item}</Fragment>))}
                                    </div>)}
                            </div>
                            <div className={topImageStyles}>{image}</div>
                        </div>
                        {bottomSection && (<div className={dividerStyles}>
                                <Divider />
                            </div>)}
                        {bottomSection}
                    </div>
                    <div className={sideImageStyles}>{image}</div>
                </section>
            </div>
        </AnalyticLinkLocationProvider>);
}
const largeContainerMaxWidth = 1220;
const mediumContainerMaxWidth = 820;
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    containerType: "inline-size",
    containerName: "Level1EmptyStateLayout",
});
const onboardingContainerStyles = css({
    flex: 1,
    display: "flex",
    gap: space["80"],
    justifyContent: "center",
    alignItems: "center",
    color: themeTokens.color.text.primary,
    paddingTop: space["32"],
    paddingRight: space["32"],
    paddingLeft: space["32"],
    paddingBottom: space["64"],
});
const contentStyles = css({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: space["24"],
    width: "100%",
    maxWidth: "620px",
    font: text.regular.default.large,
    [`@container Level1EmptyStateLayout (width < ${largeContainerMaxWidth + 1}px)`]: {
        maxWidth: "900px",
    },
});
const topSectionStyles = css({
    flex: 1,
    display: "flex",
    gap: space["64"],
    justifyContent: "center",
    alignItems: "center",
    color: themeTokens.color.text.primary,
    [`@container Level1EmptyStateLayout (width < ${mediumContainerMaxWidth + 1}px)`]: {
        flexDirection: "column-reverse",
        padding: "1.5rem 1rem",
    },
});
const topSectionContentStyles = css({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: space["24"],
    width: "100%",
    maxWidth: "620px",
    font: text.regular.default.large,
    [`@container Level1EmptyStateLayout (width < ${largeContainerMaxWidth + 1}px)`]: {
        maxWidth: "900px",
    },
    [`@container Level1EmptyStateLayout (width < ${largeContainerMaxWidth + 1}px) and (width > ${mediumContainerMaxWidth}px)`]: {
        marginBottom: "3.87rem",
    },
});
const defaultTitleStyles = css({
    all: "unset",
    font: text.heading.xLarge,
});
const actionsContainerStyles = css({
    display: "flex",
    gap: space["24"],
    alignItems: "center",
    flexWrap: "wrap",
});
const sideImageStyles = css({
    width: "31.25rem",
    [`@container Level1EmptyStateLayout (width < ${largeContainerMaxWidth + 1}px)`]: {
        display: "none",
    },
});
const topImageStyles = css({
    [`@container Level1EmptyStateLayout (width > ${largeContainerMaxWidth}px)`]: {
        display: "none",
    },
    minWidth: "18.75rem",
    [`@container Level1EmptyStateLayout (width < ${mediumContainerMaxWidth + 1}px)`]: {
        minWidth: "17.5rem",
    },
});
const dividerStyles = css({
    marginTop: space["16"],
    marginBottom: space["16"],
});
