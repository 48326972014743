import { Tooltip } from "@octopusdeploy/design-system-components";
import type { GitRefResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectGitRefSelector } from "~/areas/projects/components/GitRefSelector/ProjectGitRefSelector";
export interface BranchSelectorVNextProps {
    project: ProjectResource;
    placeholderGitRef: string;
    gitRef: GitRefResource | undefined;
    setGitRef: (gitRef: string) => void;
}
export function BranchSelectorVNext({ project, gitRef, placeholderGitRef, setGitRef }: BranchSelectorVNextProps) {
    if (!gitRef) {
        if (placeholderGitRef) {
            return <ProjectGitRefSelector onChange={setGitRef} project={project} initialGitRef={placeholderGitRef} allowBranchCreation={false}/>;
        }
        return null;
    }
    const gitRefSelector = <ProjectGitRefSelector onChange={setGitRef} project={project} initialGitRef={gitRef.CanonicalName} allowBranchCreation={false}/>;
    return <Tooltip content={gitRef.Name}>{gitRefSelector}</Tooltip>;
}
