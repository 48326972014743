/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { canCommitTo, getGitRefDescription, HasRunbooksInGit, isGitBranchResource, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useRef, useState } from "react";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import MarkdownDescription from "~/components/MarkdownDescription";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useRequiredContext } from "~/hooks/index";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { PageHeaderBranchSelectorVNext } from "../BranchSelector/PageHeaderBranchSelector";
import { useProjectRunbooksGitRef, type GitRefQueryParamsProps } from "../BranchSelector/useProjectGitRef";
import AddRunbook from "./AddRunbook";
import { LoadMoreList } from "./LoadMoreList";
import RunbooksOnboarding from "./RunbooksOnboarding";
interface FilterableRunbookCollection extends ResourceCollection<RunbookResource> {
    IsFiltered: boolean;
}
export class RunbooksPage extends DataBaseComponent<GitRefQueryParamsProps> {
    constructor(props: GitRefQueryParamsProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <RunbooksPageInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "RunbooksPage";
}
interface RunbooksPageInternalProps extends GitRefQueryParamsProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function RunbooksPageInternal({ doBusyTask, busy, errors, queryParams, setQueryParams }: RunbooksPageInternalProps) {
    const pageSize = 30;
    const navigation = useSpaceAwareNavigation();
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    const { isOpen: isAddRunbookDialogOpen, openDialog: openAddRunbookDialog, closeDialog: closeAddRunbookDialog } = useDialogTrigger();
    const firstLoad = useRef(true);
    const [runbooks, setRunbooks] = useState<FilterableRunbookCollection>();
    const [take, setTake] = useState<number>(pageSize);
    const [filter, setFilter] = useState<string>();
    const { model: project } = projectContext.state;
    const [gitRef, setGitRef] = useProjectRunbooksGitRef(project, queryParams, setQueryParams);
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const reload = useDoBusyTaskEffect(doBusyTask, async () => {
        // Keep filter locally in case it changes between when the search
        // starts and when we get the results below, so we can work out if
        // there was a filter when we made the request.
        const partialName = filter;
        const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
        const params = { skip: 0, take, partialName: filter };
        if (hasGitRunbooks) {
            if (gitRef) {
                const runbooks = await repository.Runbooks.getRunbooks(project, gitRef.CanonicalName, params);
                setRunbooks({ ...runbooks, IsFiltered: !!partialName });
            }
        }
        else {
            const runbooks = await repository.Runbooks.getRunbooks(project, undefined, params);
            setRunbooks({ ...runbooks, IsFiltered: !!partialName });
        }
        if (firstLoad.current) {
            firstLoad.current = false;
            onboardingContext.continueSampleProjectTour();
        }
    }, [gitRef, take, filter], { timeOperationOptions: timeOperationOptions.forInitialLoad(project.IsVersionControlled) });
    const onFilterChanged = (value: string) => {
        setTake(pageSize); // When resetting the search, reset the take to the default
        setFilter(value);
    };
    const addRunbookPageAction: PrimaryPageAction = {
        type: "button",
        label: "Add Runbook",
        hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        onClick: openAddRunbookDialog,
        disabled: hasGitRunbooks && !canCommitTo(gitRef),
        extraContext: hasGitRunbooks && gitRef && !canCommitTo(gitRef) ? `Cannot create a Runbook on a ${isGitBranchResource(gitRef) ? "protected branch" : getGitRefDescription(gitRef.CanonicalName)}` : undefined,
    };
    return (<ProjectPaperLayout busy={busy} errors={errors} title="Runbooks" primaryAction={addRunbookPageAction} titleAccessory={hasGitRunbooks ? <PageHeaderBranchSelectorVNext project={project} gitRef={gitRef} setGitRef={setGitRef} placeholderGitRef={queryParams.gitRef}/> : undefined}>
            <Dialog open={isAddRunbookDialogOpen}>
                <AddRunbook projectId={project.Id} onProcessCreated={async (id) => {
            navigation.navigate(links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: id }));
        }}/>
            </Dialog>
            {HasRunbooksInGit(project.PersistenceSettings) && <ProjectGitHubAppAuthCheck project={project} permission={Permission.RunbookView}/>}
            {runbooks && (<>
                    {runbooks.Items.length === 0 && !runbooks.IsFiltered ? (<RunbooksOnboarding />) : (<LoadMoreList loadingMore={!!busy} items={runbooks.Items} renderRow={(item) => (<RunbooksPageRow project={project} runbook={item} reload={async () => {
                        await reload();
                    }}/>)} getAccessibleName={(runbook) => runbook.Name} getNavigationUrl={(runbook) => links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} showLoadMore={runbooks.Items.length < runbooks.TotalResults} onLoadMore={() => {
                    setTake((currentTake) => currentTake + pageSize);
                }} onFilterChanged={onFilterChanged}/>)}
                </>)}
        </ProjectPaperLayout>);
}
interface RunbooksPageRowProps {
    project: ProjectResource;
    runbook: RunbookResource;
    reload: () => Promise<void>;
}
function RunbooksPageRow({ project, runbook, reload }: RunbooksPageRowProps) {
    const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description}/>;
    const CloneDialog = () => <AddRunbook projectId={project.Id} onProcessCreated={async (id) => reload()} cloneId={runbook.Id}/>;
    const clonePermission = {
        permission: Permission.RunbookEdit,
        projectGroup: project && project.ProjectGroupId,
        wildcard: true,
    };
    const overFlowActions: Array<MenuItem | MenuItem[]> = [];
    overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, clonePermission));
    overFlowActions.push([
        OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }), {
            permission: Permission.EventView,
            wildcard: true,
        }),
    ]);
    const runAction = isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, projectGroup: project.ProjectGroupId, wildcard: true }) ? (<NavigationButton label="Run..." href={links.runbookRunNowPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} type={NavigationButtonType.Secondary}/>) : null;
    return (<ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction!}>
            <ListTitle>{runbook.Name}</ListTitle>
            {runbookDescription}
        </ListItem>);
}
