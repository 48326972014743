/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FeedResource, PackageReference } from "@octopusdeploy/octopus-server-client";
import { FeedType, PackageAcquisitionLocation } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { DataBaseComponentState } from "../../DataBaseComponent";
import { DataBaseComponent } from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import PackageSelector from "../../PackageSelector/PackageSelector";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
const DefaultYamlFileLocation = "values.yaml";
interface PackageReferenceProps {
    packageReference: PackagedHelmValuesReference;
    runOn?: RunOn;
    feeds: FeedResource[];
    localNames: string[];
    projectId: string;
    onChange(PackageReference: PackagedHelmValuesReference): boolean;
    refreshFeeds(): Promise<void>;
}
interface ScriptPackageReferenceState extends DataBaseComponentState {
    packageReferenceId: string;
    packageId: string;
    feedId: string;
    packageVersion?: string;
    valuesFilePath: string;
    name: string | undefined;
}
export type PackagedHelmValuesReference = PackageReference<PackagedHelmValuesProperties>;
export interface PackagedHelmValuesProperties extends ScriptPackageProperties {
    ValuesFilePath: string;
    PerformVariableReplace?: string;
}
export class PackagedHelmValuesDialog extends DataBaseComponent<PackageReferenceProps, ScriptPackageReferenceState> {
    constructor(props: PackageReferenceProps) {
        super(props);
        this.state = {
            packageReferenceId: this.props.packageReference.Id,
            packageId: this.props.packageReference.PackageId,
            feedId: this.props.packageReference.FeedId,
            packageVersion: this.props.packageReference.Version,
            valuesFilePath: this.props.packageReference.Properties["ValuesFilePath"],
            name: this.props.packageReference.Name,
        };
    }
    render() {
        const feed = _.find(this.props.feeds, (f) => f.Id === this.state.feedId);
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title="Reference a Package">
                {this.props.packageReference && (<div>
                        <PackageSelector packageId={this.state.packageId} feedId={this.state.feedId} packageVersion={this.state.packageVersion} onPackageIdChange={(packageId) => this.setState({ packageId })} onFeedIdChange={(feedId) => this.setState({ feedId })} onPackageVersionChange={(packageVersion) => this.setState({ packageVersion })} localNames={this.props.localNames} projectId={this.props.projectId} feeds={this.props.feeds} feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.GitHub, FeedType.Maven, FeedType.ArtifactoryGeneric]} refreshFeeds={this.props.refreshFeeds}/>
                        <div style={{ paddingTop: "1rem" }}>
                            <VariableLookupText localNames={this.props.localNames} value={this.state.valuesFilePath} onChange={(x) => this.setState({ valuesFilePath: x })} placeholder={DefaultYamlFileLocation} label="Relative path to values file"/>
                            <Note>
                                A newline-separated list of file names, relative to the package root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before used. Extended template and wildcard
                                syntax is supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                            </Note>
                        </div>
                    </div>)}
            </OkDialogLayout>);
    }
    save = () => {
        const packageReference = {
            Id: this.state.packageReferenceId,
            PackageId: this.state.packageId,
            FeedId: this.state.feedId,
            Name: this.state.name,
            Properties: {
                ValuesFilePath: this.state.valuesFilePath || DefaultYamlFileLocation,
                Extract: "true",
            },
            AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
        };
        return this.props.onChange(packageReference);
    };
    static displayName = "PackagedHelmValuesDialog";
}
