/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Callout, Checkbox, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, fontWeight, letterSpacing, lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, LifecycleResource, ProjectGroupResource, ProjectResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import AdvancedProjectSection from "~/areas/projects/components/Projects/AdvancedProjectSection";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { radioButtonStyles } from "~/components/ProjectBasedActivation/RectangularRadioButtonStyles";
import { required, Text } from "~/components/form";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { ProjectCardIcon } from "./ProjectCardIcon";
export interface AddProjectDialogProps {
    spaceId: string;
    groupId?: string;
    projectCreated: (project: ProjectResource, option: ProjectCreatedOption) => void;
    onCancelled?: () => void;
    hideEnvironmentConfigWarning?: boolean;
    projectNameTextBoxLabel?: string;
    cancelButtonLabel?: string;
    saveButtonLable?: string;
    // Consider to remove this prop with "KubernetesGuidedSetupFeatureToggle" and feature slug kubernetes-guided-setup
    isKubernetesGuidedSetup?: boolean;
    isOnboarding?: boolean;
    title?: string;
}
export type ProjectCreatedOption = {
    vcsRedirect: boolean;
    newlyCreatedProjectRedirect: boolean;
    infrastructure: ProjectInfrastructure | undefined;
    step: string | undefined;
};
export enum ProjectInfrastructure {
    Kubernetes = "Kubernetes",
    Azure = "Azure",
    AWS = "AWS",
    Linux = "Linux",
    Windows = "Windows",
    Other = "Other"
}
export enum KubernetesStep {
    Helm = "Octopus.HelmChartUpgrade",
    Kustomize = "Octopus.Kubernetes.Kustomize",
    Yaml = "Octopus.KubernetesDeployRawYaml"
}
type InternalAddProjectDialogProps = AddProjectDialogProps & {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
};
interface AddProjectDialogState extends DataBaseComponentState {
    vcsRedirect: boolean;
    name: string;
    description: string;
    newProjectId: string | undefined;
    projectGroupId: string | undefined;
    selectedLifecycle: LifecycleResource | undefined;
    projectGroups: ProjectGroupResource[];
    lifecycles: LifecycleResource[];
    environmentsById: ResourcesById<EnvironmentResource>;
    showLifecycleMap: boolean;
    showAdvanced: boolean;
    infrastructure: ProjectInfrastructure | "";
    step: string;
}
class AddProjectInternal extends DataBaseComponent<InternalAddProjectDialogProps, AddProjectDialogState> {
    constructor(props: InternalAddProjectDialogProps) {
        super(props);
        this.state = {
            vcsRedirect: false,
            name: "",
            description: "",
            projectGroupId: undefined,
            selectedLifecycle: undefined,
            projectGroups: [],
            lifecycles: [],
            environmentsById: {},
            newProjectId: undefined,
            showLifecycleMap: false,
            showAdvanced: false,
            infrastructure: "",
            step: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [projectGroups, lifecycles, environmentsById] = await Promise.all([
                repository.ProjectGroups.all(),
                isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.all() : Promise.resolve([]),
                repository.Environments.allById(),
            ]);
            const lifecycle = lifecycles.find((x: LifecycleResource) => x.Name === "Default Lifecycle");
            const projectGroup = projectGroups.find((x) => x.Name === "All Projects" || x.Name === "Default Project Group");
            const projectGroupId = this.props.groupId || (projectGroup ? projectGroup.Id : projectGroups[0].Id);
            this.setState({
                lifecycles,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecycles[0],
                projectGroupId,
            });
        });
    }
    async save() {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Project",
                isCaCenabled: this.state.vcsRedirect ? "True" : "False",
            };
            await this.props.trackAction("Save Project", ev, async (cb: AnalyticErrorCallback) => {
                if (!this.state.projectGroupId) {
                    this.setValidationErrors("You need to select a project group");
                    cb("Missing Project Group");
                    return false;
                }
                if (!this.state.selectedLifecycle) {
                    this.setValidationErrors("You need to select a lifecycle");
                    cb("Missing Lifestyle");
                    return false;
                }
                const result = await repository.Projects.create({
                    Name: this.state.name,
                    Description: this.state.description,
                    ProjectGroupId: this.state.projectGroupId,
                    LifecycleId: this.state.selectedLifecycle.Id,
                    ProjectConnectivityPolicy: { AllowDeploymentsToNoTargets: true },
                });
                await repository.Projects.setProjectIntents(result.Slug, result.SpaceId, {
                    SpaceId: result.SpaceId,
                    ProjectId: result.Id,
                    IsVcsProject: !!this.state.vcsRedirect,
                });
                // refresh permissions to include the new project
                if (session.currentUser) {
                    const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
                    session.refreshPermissions(permissionSet);
                }
                this.props.projectCreated(result, {
                    vcsRedirect: !!this.state.vcsRedirect,
                    newlyCreatedProjectRedirect: true,
                    infrastructure: this.state.infrastructure ? this.state.infrastructure : undefined,
                    step: Object.values(KubernetesStep).some((x) => x === this.state.step) ? this.state.step : undefined,
                });
                return true;
            });
        });
        return false;
    }
    refreshLifecycles() {
        this.doBusyTask(async () => {
            if (isAllowed({ permission: Permission.LifecycleView })) {
                const lifecycles = await repository.Lifecycles.all();
                let selectedLifecycle = this.state.selectedLifecycle;
                if (lifecycles.every((x) => x.Id !== this.state.selectedLifecycle?.Id)) {
                    selectedLifecycle = lifecycles.length ? lifecycles[0] : undefined;
                }
                this.setState({ ...this.state, lifecycles, selectedLifecycle });
            }
        });
    }
    render() {
        if (!this.state.lifecycles) {
            return <SaveDialogLayout title={this.props.title ?? "Add New Project"} busy={true} errors={this.errors} onSaveClick={() => this.save()}/>;
        }
        const title = !this.props.title ? "Add New Project" : <span className={addProjectInternalStyles.projectTitle}>{this.props.title}</span>;
        const showAdvancedOptions = !this.props.isKubernetesGuidedSetup || !this.props.isOnboarding;
        const showAdvancedButton = this.state.lifecycles.length <= 1;
        const saveButtonLabel = this.props.isKubernetesGuidedSetup ? this.props.saveButtonLable : this.state.vcsRedirect ? "Save and configure VCS" : undefined;
        return (<SaveDialogLayout title={title} busy={this.state.busy} errors={this.errors} saveButtonLabel={saveButtonLabel} saveButtonDisabled={this.state.name.length === 0} onSaveClick={() => this.save()} cancelButtonLabel={this.props.cancelButtonLabel} onCancelClick={() => {
                this.props.dispatchAction("Cancel adding Project", { resource: "Project", action: Action.Cancel });
                this.props.onCancelled?.();
                return true;
            }}>
                <PermissionCheck permission={Permission.LifecycleView} alternate={<Callout type={"information"} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to create a project
                        </Callout>}>
                    {!this.state.busy && this.state.lifecycles.length === 0 && (<Callout type={"danger"} title="No lifecycles Configured">
                            <InternalLink to={links.lifecyclesPage.generateUrl({ spaceId: this.props.spaceId })}>Configure your lifecycles</InternalLink> before setting up a project.
                        </Callout>)}
                    {!this.state.busy && Object.keys(this.state.environmentsById).length === 0 && !this.props.hideEnvironmentConfigWarning && (<Callout type={"warning"} title="No Environments Configured">
                            Please consider <InternalLink to={links.infrastructureOverviewPage.generateUrl({ spaceId: this.props.spaceId })}>configuring your infrastructure</InternalLink> before setting up a project.
                        </Callout>)}
                    <div className={classNames({ [addProjectInternalStyles.projectNameContainer]: true, [addProjectInternalStyles.projectError]: this.errors !== undefined })}>
                        {this.props.isKubernetesGuidedSetup && (<div className={addProjectInternalStyles.projectCard}>
                                <ProjectCardIcon />
                            </div>)}
                        <Text label={this.props.projectNameTextBoxLabel ?? "New project name"} error={this.props.isKubernetesGuidedSetup ? this.errors?.errors?.join(", ") : ""} value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true}/>
                    </div>

                    {!this.props.isKubernetesGuidedSetup ? (<>
                            <Checkbox label="Use version control for this project" value={!!this.state.vcsRedirect} onChange={() => {
                    this.setState({ vcsRedirect: !this.state.vcsRedirect });
                }} note={"If set, you will be redirected to the VCS Settings page to configure version control when you save."}/>
                        </>) : (<div className={addProjectInternalStyles.infrastructureContainer}>
                            <div className={classNames(addProjectInternalStyles.question, "git")}>Store project process, settings, and non-sensitive variables in</div>
                            <div className={radioButtonStyles}>
                                <RadioButtonGroup value={Number(this.state.vcsRedirect).toString()} onChange={(newValue: string) => {
                    this.setState({ vcsRedirect: !!Number(newValue) });
                }} horizontal>
                                    <RadioButton className={classNames({ ["active"]: this.state.vcsRedirect === false })} label={<span>Octopus</span>} value="0"/>
                                    <RadioButton className={classNames({ ["active"]: this.state.vcsRedirect === true })} label="Git Repository" value="1"/>
                                </RadioButtonGroup>
                            </div>
                            <div className={classNames(addProjectInternalStyles.question, "infrastructure")}>Deploy to</div>
                            <div className={radioButtonStyles}>
                                <RadioButtonGroup value={this.state.infrastructure} onChange={(newValue: ProjectInfrastructure | "") => {
                    this.setState({ infrastructure: newValue, step: newValue == ProjectInfrastructure.Kubernetes ? "" : this.state.step });
                }} horizontal>
                                    {Object.values(ProjectInfrastructure).map((value: string) => (<RadioButton className={classNames({ ["active"]: this.state.infrastructure == value })} key={value!} label={value} value={value}/>))}
                                </RadioButtonGroup>
                            </div>
                            {this.state.infrastructure == ProjectInfrastructure.Kubernetes && (<>
                                    <div className={classNames(addProjectInternalStyles.question, "step")}>Manage with</div>
                                    <div className={radioButtonStyles}>
                                        <RadioButtonGroup value={this.state.step} onChange={(newValue: string) => {
                        this.setState({ step: newValue });
                    }} horizontal>
                                            <RadioButton className={classNames({ ["active"]: this.state.step == KubernetesStep.Helm })} label="Helm" value={KubernetesStep.Helm}/>
                                            <RadioButton className={classNames({ ["active"]: this.state.step == KubernetesStep.Kustomize })} label="Kustomize" value={KubernetesStep.Kustomize}/>
                                            <RadioButton className={classNames({ ["active"]: this.state.step == KubernetesStep.Yaml })} label="YAML files" value={KubernetesStep.Yaml}/>
                                            <RadioButton className={classNames({ ["active"]: this.state.step == "Other" })} label="Other" value="Other"/>
                                        </RadioButtonGroup>
                                    </div>
                                </>)}
                        </div>)}

                    {/* Advanced Options */}
                    {showAdvancedOptions && showAdvancedButton && (<ActionButton label={this.state.showAdvanced ? "Hide advanced" : "Show advanced"} type={ActionButtonType.Ternary} onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showAdvanced: !this.state.showAdvanced });
                }}/>)}
                    {showAdvancedOptions && (!showAdvancedButton || this.state.showAdvanced) && (<AdvancedProjectSection {...this.state} spaceId={this.props.spaceId} doBusyTask={this.doBusyTask} onDescriptionChanged={this.handleDescriptionChanged} onProjectGroupChanged={(newValue) => this.setState({ projectGroupId: newValue })} onLifecycleChanged={this.handleLifeCycleChange} onRefreshLifecycles={() => this.refreshLifecycles()}/>)}
                </PermissionCheck>
            </SaveDialogLayout>);
    }
    private handleLifeCycleChange = (value: string | undefined) => {
        const lifecycles = this.state.lifecycles?.filter((l) => l.Id === value) || [];
        this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0] : undefined });
    };
    private handleDescriptionChanged = (description: string) => {
        this.setState({ description });
    };
    static displayName = "AddProjectInternal";
}
const AddProject: React.FC<AddProjectDialogProps> = (props) => {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <AddProjectInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction}/>;
};
AddProject.displayName = "AddProject"
const addProjectInternalStyles = {
    projectTitle: css({
        overflow: "hidden",
        textOverflow: "ellipsis",
        font: text.heading.large,
    }),
    projectNameContainer: css({
        display: "flex",
    }),
    projectError: css({
        marginTop: space["32"],
    }),
    projectCard: css({
        width: "46px",
        minWidth: "46px",
        height: "46px",
        borderRadius: borderRadius.small,
        backgroundColor: themeTokens.color.background.secondary.default,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: space["16"],
        "& svg": {
            width: "32px",
            height: "auto",
        },
        "& svg path": {
            fill: themeTokens.color.icon.primary,
        },
    }),
    question: css({
        fontWeight: fontWeight["400"],
        lineHeight: lineHeight["small"],
        letterSpacing: letterSpacing["wide"],
        fontSize: fontSize["base"],
        "&.git": {
            marginTop: space["16"],
        },
        "&.infrastructure,&.step": {
            marginTop: space["32"],
        },
    }),
    infrastructureContainer: css({
        marginBottom: space[64],
    }),
};
export default AddProject;
