import { css } from "@emotion/css";
import { text } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { isGitBranch } from "@octopusdeploy/octopus-server-client";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
export function ProjectCreatePullRequestLink() {
    const projectContext = useProjectContext();
    const [pullRequestLink, setPullRequestLink] = useState<string | undefined>(undefined);
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const onPullRequestLinkClicked = useCallback(() => {
        dispatchAction("Create Pull Request");
    }, [dispatchAction]);
    useEffect(() => {
        if (!projectContext.state.gitRef) {
            return;
        }
        repository.Projects.getPullRequestLink(projectContext.state.model, projectContext.state.gitRef.CanonicalName)
            .then((resp) => {
            if (resp.Link) {
                setPullRequestLink(resp.Link);
            }
        })
            .catch((e) => {
            logger.warn(e, "Failed to get pull request link");
        });
    }, [projectContext.state.model, projectContext.state.gitRef]);
    if (!projectContext.state.model.IsVersionControlled) {
        return null;
    }
    const currentHead = projectContext.state.gitRef?.CanonicalName;
    const isDefaultBranch = projectContext.state.isDefaultBranch;
    const canCreatePullRequest = isGitBranch(currentHead) && !isDefaultBranch && pullRequestLink;
    return canCreatePullRequest ? (<ExternalLink href={pullRequestLink} onClick={onPullRequestLinkClicked} trackAnalytics={false} className={createPullRequestLinkStyles}>
            Create pull request
        </ExternalLink>) : null;
}
const createPullRequestLinkStyles = css({
    font: text.regular.default.medium,
});
