/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { ActionButton, Checkbox } from "@octopusdeploy/design-system-components";
import type { ChannelGitResourceRuleResource, ChannelResource, ChannelVersionRuleResource, DeploymentActionPackageResource, DeploymentActionResource, DeploymentProcessRepository, GitRefResource, LifecycleResource, ProjectResource, Repository, ValidateGitRefV2Response, } from "@octopusdeploy/octopus-server-client";
import { deploymentActionPackages, displayName, HasGitPersistenceSettings, isGitCommit, isGitTag, OctopusError, Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import DesignRule from "~/areas/projects/components/Channels/DesignRule";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { type ProjectContextProps } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { OverflowMenuDeleteItem, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import TagsList from "~/components/TagsList/TagsList";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
import { ExpandableFormSection, FormSectionHeading, Note, Summary, Text } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { required } from "~/components/form/Validators";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import Select from "~/primitiveComponents/form/Select/Select";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import StringHelper from "~/utils/StringHelper";
import DeploymentActionPackageMultiSelect, { DeploymentActionPackageReferenceDataItem } from "../../../../components/MultiSelect/DeploymentActionPackageMultiSelect";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ChannelGitProtectionRulesEdit } from "./ChannelGitProtectionRulesEdit";
import type { DeploymentActionGitDependencyDetails } from "./GitProtectionRules/GitResourceRule";
import { getDeploymentActionGitDependenciesWithDetails } from "./GitProtectionRules/getDeploymentActionGitDependenciesWithDetails";
import styles from "./style.module.less";
type CreateOrEditChannelProps = {
    trackAction: AnalyticTrackedActionDispatcher;
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
    lookupData: ChannelLookupData;
    channel: ChannelResource | "create new channel";
    pageTitle?: string;
};
interface CreateOrEditChannelState extends FormBaseComponentState<ChannelResource> {
    lifecycles: LifecycleResource[];
    deploymentActions: DeploymentActionResource[] | OctopusError;
    packageActions: DeploymentActionPackageResource[];
    gitDependencyActions: DeploymentActionGitDependencyDetails[];
    redirectTo?: LinkHref;
}
export async function channelLookupDataLoader(repository: Repository, projectContext: Promise<ProjectContextProps>): Promise<ChannelLookupData> {
    const lifecycles = repository.Lifecycles.all();
    const tagIndex = tenantTagsets.getTagIndex();
    const { state: { model: project, projectContextRepository, gitRefValidationError }, } = await projectContext;
    const deploymentActions = getDeploymentActionsWithErrorHandlingForGitProjects(project, projectContextRepository.DeploymentProcesses, gitRefValidationError);
    return {
        deploymentActions: await deploymentActions,
        lifecycles: await lifecycles,
        tagIndex: await tagIndex,
    };
}
export interface ChannelLookupData {
    deploymentActions: DeploymentActionResource[] | OctopusError;
    lifecycles: LifecycleResource[];
    tagIndex: TagIndex;
}
export class CreateOrEditChannel extends FormBaseComponent<CreateOrEditChannelProps, CreateOrEditChannelState, ChannelResource> {
    private static NoId = "-1";
    constructor(props: CreateOrEditChannelProps) {
        super(props);
        const { deploymentActions, lifecycles } = props.lookupData;
        const channel = this.props.channel === "create new channel"
            ? ({
                Id: null!,
                ProjectId: props.project.Id,
                SpaceId: "",
                Name: "",
                Description: "",
                IsDefault: false,
                LifecycleId: null!,
                Rules: [],
                TenantTags: [],
                Links: null!,
                GitReferenceRules: [],
                GitResourceRules: [],
            } satisfies ChannelResource)
            : this.props.channel;
        const deploymentActionsOrEmpty = deploymentActions instanceof OctopusError ? [] : deploymentActions;
        const packageActions = deploymentActionPackages(deploymentActionsOrEmpty);
        const gitDependencyActions = getDeploymentActionGitDependenciesWithDetails(deploymentActionsOrEmpty);
        const sortedLifecycles = _.sortBy(lifecycles, (l) => l.Name);
        sortedLifecycles.unshift({
            Phases: [],
            Name: "Inherit from project",
            Id: CreateOrEditChannel.NoId,
            SpaceId: channel.SpaceId,
            ReleaseRetentionPolicy: null!,
            TentacleRetentionPolicy: null!,
            Links: null!,
        });
        this.state = {
            model: channel,
            cleanModel: _.cloneDeep(channel),
            lifecycles: sortedLifecycles,
            deploymentActions,
            packageActions,
            gitDependencyActions,
        };
        this.throttledVersionRange = _.throttle(this.throttledVersionRange, 500);
        this.throttledTag = _.throttle(this.throttledTag, 500);
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const isCreatingNewChannel = this.props.channel === "create new channel";
        const title = this.props.pageTitle ?? (this.state.model ? this.state.model.Name : StringHelper.ellipsis);
        const convertedOverflowActions = this.getOverflowMenuItems();
        const saveText: string = isCreatingNewChannel ? "Channel created" : "Channel details updated";
        const channelGitProtectionRulesEnabled = isFeatureToggleEnabled("ChannelGitProtectionRulesFeatureToggle");
        const deploymentActionError = this.state.deploymentActions instanceof OctopusError ? this.state.deploymentActions : undefined;
        return (<Form saveText={saveText} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.saveChannel}>
                {({ FormContent, createSaveAction }) => (<ProjectPaperLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbsItems={[{ label: "Channels", pageUrl: links.channelsPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug }) }]} primaryAction={createSaveAction({ saveButtonLabel: "Save", saveButtonBusyLabel: "Saving" })} overflowActions={convertedOverflowActions.menuItems}>
                        {convertedOverflowActions.dialogs}
                        <FormContent expandAllOnMount={isCreatingNewChannel}>
                            <TransitionAnimation>
                                <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.Name} slug={this.state.model.Slug}/>) : Summary.placeholder("Please enter a name for your channel")} help="Enter a name for your channel.">
                                    <Text value={this.state.model.Name || ""} onChange={(Name) => this.setModelState({ Name })} label="Channel name" validate={required("Please enter a channel name")} error={this.getFieldError("Name")} autoFocus={true}/>
                                    <Note>
                                        A short, memorable, unique name for this channel. Example: <em>1.x Normal, 2.x Beta</em>
                                    </Note>

                                    {!isCreatingNewChannel && (<SlugEditor value={this.state.model.Slug || ""} name={this.state.model.Name} originalSlug={this.state.cleanModel?.Slug ?? ""} onChange={(Slug) => this.setModelState({ Slug })} label="Channel slug" validate={required("Please enter a channel slug")} error={this.getFieldError("Slug")}/>)}
                                </ExpandableFormSection>
                                <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your channel.">
                                    <MarkdownEditor value={this.state.model.Description} label="Channel description" onChange={(Description) => this.setModelState({ Description })}/>
                                </ExpandableFormSection>
                                <ExpandableFormSection errorKey="lifecycle" title="Lifecycle" summary={this.lifecycleSummary()} help="Select a lifecycle for your channel.">
                                    <Select value={this.state.model.LifecycleId || CreateOrEditChannel.NoId} onChange={this.handleLifecycleChanged} items={this.state.lifecycles.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Lifecycle" sortItems={false}/>
                                    <Note>
                                        The lifecycle defines how releases can be promoted between environments. Lifecycles can be defined{" "}
                                        <InternalLink to={links.lifecyclesPage.generateUrl({ spaceId: this.props.project.SpaceId })}>here</InternalLink>. If no lifecycle is selected, the default lifecycle for the project will be used.
                                    </Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection errorKey="defaultChannel" title="Is Default Channel" summary={this.state.model.IsDefault
                    ? Summary.summary(<span>
                                                      <strong>This channel</strong> will be chosen by default when creating releases
                                                  </span>)
                    : Summary.summary(<span>
                                                      <strong>A different channel</strong> will be chosen by default when creating releases
                                                  </span>)} help="This channel will be selected by default when creating releases.">
                                    <Checkbox label="Default channel" value={this.state.model.IsDefault} onChange={(IsDefault) => this.setModelState({ IsDefault })}/>
                                </ExpandableFormSection>

                                <FormSectionHeading title={channelGitProtectionRulesEnabled ? "Package Version Rules" : "Version Rules"}/>
                                {this.props.gitRefValidationError && (<WarningPanel message={`An error has occurred showing steps that use packages in the deployment process: ${this.props.gitRefValidationError.Message}. Please select a different branch or tag from the deployment process screen to view steps.`} warnings={[]}/>)}
                                {deploymentActionError && (<WarningPanel message={`An error has occurred showing steps that use packages in the deployment process: ${deploymentActionError.ErrorMessage}`} warnings={deploymentActionError.Errors ?? []}/>)}
                                <RemovableExpandersList helpElement={<div>
                                            {channelGitProtectionRulesEnabled
                        ? "Use package version rules to restrict which versions of packages can be used on steps when creating releases in this channel."
                        : "Define package version rules that will be enforced when creating releases in this channel."}
                                            <Note>
                                                Learn about <ExternalLink href={"ChannelVersionRules"}>channel version rules</ExternalLink>.
                                            </Note>
                                        </div>} typeDisplayName={channelGitProtectionRulesEnabled ? "Package Version Rule" : "Version Rule"} data={this.state.model.Rules} listActions={[<ActionButton key="AddVersion" label={channelGitProtectionRulesEnabled ? "Add rule" : "Add version rule"} onClick={() => this.addVersionRule()}/>]} onRow={(item: ChannelVersionRuleResource, index: number) => {
                    return this.renderVersionRule(item, index, deploymentActionError === undefined);
                }} onRowSummary={(item: ChannelVersionRuleResource) => {
                    return this.ruleSummary(item);
                }} onRowHelp={() => {
                    return channelGitProtectionRulesEnabled
                        ? "Use package version rules to restrict which versions of packages can be used on steps when creating releases in this channel."
                        : "Define package version rules that will be enforced when creating releases in this channel.";
                }} onRemoveRowByIndex={this.handleVersionRuleDeleteByIndex}/>
                                {channelGitProtectionRulesEnabled && (<ChannelGitProtectionRulesEdit project={this.props.project} channel={this.state.model} gitRef={this.props.gitRef} gitRefValidationError={this.props.gitRefValidationError} gitDependencyActions={this.state.gitDependencyActions} deploymentActionsError={deploymentActionError} doBusyTask={this.doBusyTask} onGitReferenceRulesChanged={(GitReferenceRules) => this.setModelState({ GitReferenceRules })} onGitResourceRulesChanged={(GitResourceRules) => this.setModelState({ GitResourceRules })}/>)}

                                <FeatureToggle feature={Feature.MultiTenancy}>
                                    {(this.props.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted || this.state.model.TenantTags.length > 0) && (<React.Fragment>
                                            <FormSectionHeading title="Tenants"/>
                                            <ExpandableFormSection errorKey="tenantTags" title="Tenants" summary={this.state.model.TenantTags.length > 0
                        ? Summary.summary(<span>
                                                                  Releases in this channel can only be deployed to certain tenants:
                                                                  <TagsList canonicalNames={this.state.model.TenantTags} tagIndex={this.props.lookupData.tagIndex}/>
                                                              </span>)
                        : Summary.default("Releases in this channel can be deployed to any tenants")} help={"Choose which tenants the releases in this channel apply to."}>
                                                <Note>
                                                    Releases in this channel will only be deployed to tenants matching this filter. Clear the filter to make releases in this channel available to all tenants. Learn about{" "}
                                                    <ExternalLink href={"TenantsAndChannels"}>tenants and channels</ExternalLink>.
                                                </Note>
                                                <AdvancedTenantTagsSelector emptyFilterMeansAllTenants={true} selectedTenantTags={this.state.model.TenantTags} doBusyTask={this.doBusyTask} onChange={(TenantTags) => this.setModelState({ TenantTags })} showPreviewButton={true}/>
                                            </ExpandableFormSection>
                                        </React.Fragment>)}
                                </FeatureToggle>
                            </TransitionAnimation>
                        </FormContent>
                    </ProjectPaperLayout>)}
            </Form>);
    }
    private getOverflowMenuItems(): ConvertedOverflowMenuItems {
        const overFlowActions: (OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuDisabledItem | OverflowMenuNavLink[])[] = this.props.channel !== "create new channel" && this.state.model
            ? [
                OverflowMenuItems.deleteItemDefault("channel", this.deleteChannel, {
                    permission: Permission.ProcessEdit,
                    project: this.props.project.Id,
                    tenant: "*",
                }, "The channel and all steps and variables scoped only to this channel will be permanently deleted."),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        return OverflowMenuConverterVNext.convertAll(overFlowActions);
    }
    private ruleSummary(rule: ChannelVersionRuleResource) {
        const summarySpan = (<span>
                Applies to <strong>{rule.ActionPackages.map((pkg) => displayName(pkg)).join(", ")}</strong>
                &nbsp;with a version range matching <strong>{rule.VersionRange}</strong>
                {!!rule.Tag && (<span>
                        {" "}
                        and a pre-release tag matching <strong>{rule.Tag}</strong>
                    </span>)}
            </span>);
        return Summary.summary(summarySpan);
    }
    private lifecycleSummary() {
        if (this.state.model.LifecycleId) {
            return Summary.summary(this.state.lifecycles.find((l) => l.Id === this.state.model.LifecycleId)!.Name);
        }
        return Summary.default("Inherited from project");
    }
    private handleLifecycleChanged = (lifecycleId: string | undefined) => {
        this.setModelState({
            LifecycleId: lifecycleId === CreateOrEditChannel.NoId ? null! : lifecycleId!,
        });
    };
    private addGitChannelProtectionRules = (gitReferenceRules: string[]) => {
        this.setState((state) => ({
            model: {
                ...state.model,
                GitReferenceRules: gitReferenceRules,
            },
        }));
    };
    private addVersionRule() {
        const rule: ChannelVersionRuleResource = {
            Id: null!,
            Tag: "",
            VersionRange: "",
            ActionPackages: [],
            Links: null!,
        };
        this.setState((state) => {
            return {
                model: {
                    ...state.model,
                    Rules: [...state.model.Rules, rule],
                },
            };
        });
    }
    private handleVersionRuleDeleteByIndex = (index: number) => {
        this.setState((state) => {
            const Rules = state.model.Rules.filter((x, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    Rules,
                },
            };
        });
    };
    private renderVersionRule(item: ChannelVersionRuleResource, versionRuleIndex: number, hasLoadedSteps: boolean) {
        const allSelectedActionPackages = this.state.model.Rules.map((r) => r.ActionPackages).reduce((a, b) => a.concat(b), []); // All of the selected packages across all version rules
        function actionIsEqual(a: DeploymentActionPackageResource, b: DeploymentActionPackageResource) {
            return a.DeploymentAction === b.DeploymentAction && (a.PackageReference || "") === (b.PackageReference || "");
        }
        // Once a package is selected it shouldn't show for any other version rules. This is the list
        // of all packages that are not currently selected in any version rules for this channel.
        const autoCompleteActionPackages = this.state.packageActions
            .filter((a) => !allSelectedActionPackages.some((b) => actionIsEqual(a, b)))
            .map((x) => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex((y) => actionIsEqual(x, y)))));
        const itemSelectedActionPackages = this.state.packageActions
            .filter((a) => item.ActionPackages.some((b) => actionIsEqual(a, b)))
            .map((x) => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex((y) => actionIsEqual(x, y)))));
        // All of the packages that are not available in any steps. This will only be populated for
        // version controlled projects where steps might not exist on the current selected branch.
        const selectedActionPackagesNotInTheProcess = item.ActionPackages.filter((a) => !autoCompleteActionPackages.some((b) => actionIsEqual(a, b.ActionPackage)) && !itemSelectedActionPackages.some((b) => actionIsEqual(a, b.ActionPackage))).map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)));
        const allSelectedActionPackagesForRule = [...itemSelectedActionPackages, ...selectedActionPackagesNotInTheProcess];
        const deploymentActionsOrEmpty = this.state.deploymentActions instanceof OctopusError ? [] : this.state.deploymentActions;
        const deploymentActionError = this.state.deploymentActions instanceof OctopusError ? this.state.deploymentActions : undefined;
        return (<div>
                <DeploymentActionPackageMultiSelect disabled={!hasLoadedSteps} error={deploymentActionError === undefined ? undefined : "Error loading package steps"} items={[...autoCompleteActionPackages, ...allSelectedActionPackagesForRule]} value={allSelectedActionPackagesForRule.map((x) => x.Id)} label="Package step(s)" onChange={(actionPackageIndexes) => {
                return this.updateRuleProperty(versionRuleIndex, "ActionPackages", actionPackageIndexes.map((i) => {
                    const actionPackageIndex = Number(i);
                    if (Number.isNaN(actionPackageIndex)) {
                        // Action packages references for steps that are not in the process will have the action + package
                        // name as the identifier (Action Name/PackageName). This will only ever happen for version controlled
                        // processes. We need to keep the selection in place, or the save will wipe the rules that were set
                        // on a different branch.
                        return selectedActionPackagesNotInTheProcess.find((ap) => displayName(ap.ActionPackage) == i)!.ActionPackage;
                    }
                    else {
                        return this.state.packageActions[actionPackageIndex];
                    }
                }));
            }} openOnFocus={false}/>
                {this.props.project.IsVersionControlled && deploymentActionError === undefined && !this.props.gitRefValidationError && this.renderMessage()}
                <Text value={item.VersionRange || ""} onChange={(value) => this.updateRuleProperty(versionRuleIndex, "VersionRange", value)} label="Version range"/>
                <Note>
                    Use the <ExternalLink href="NuGetVersioning">NuGet</ExternalLink> or <ExternalLink href="MavenVersioning">Maven</ExternalLink> versioning syntax (depending on the feed type) to specify the range of versions to include.
                </Note>
                <Text value={item.Tag || ""} onChange={(value) => this.updateRuleProperty(versionRuleIndex, "Tag", value)} label="Pre-release tag"/>
                <Note>
                    A regular-expression which will select on the <ExternalLink href="NuGetVersioning">SemVer</ExternalLink> pre-release tag or the <ExternalLink href="MavenVersionParser"> Maven</ExternalLink> qualifier.
                </Note>
                <Note>
                    Check our <ExternalLink href="ChannelVersionRuleTags">documentation</ExternalLink> for more information on tags along with examples.
                </Note>
                <div className={styles.designRuleButton}>
                    <OpenDialogButton label="Design rule">
                        <DesignRule model={item} project={this.props.project} deploymentActions={deploymentActionsOrEmpty} onOkClick={(rule) => this.updateRule(versionRuleIndex, rule)}/>
                    </OpenDialogButton>
                </div>
            </div>);
    }
    private addGitResourceRule() {
        const rule: ChannelGitResourceRuleResource = {
            Id: null!,
            GitDependencyActions: [],
            Rules: [],
        };
        this.setState((state) => {
            return {
                model: {
                    ...state.model,
                    GitResourceRules: [...state.model.GitResourceRules, rule],
                },
            };
        });
    }
    private handleGitResourceRuleDeleteByIndex = (index: number) => {
        this.setState((state) => {
            const GitResourceRules = state.model.GitResourceRules.filter((x, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    GitResourceRules,
                },
            };
        });
    };
    private renderMessage() {
        const gitRefCanonical = this.props.gitRef?.CanonicalName;
        let gitRefType = "branch";
        if (isGitCommit(gitRefCanonical)) {
            gitRefType = "commit";
        }
        else if (isGitTag(gitRefCanonical)) {
            gitRefType = "tag";
        }
        return (<Note>
                Showing steps from the <code>{this.props.gitRef?.Name}</code> {gitRefType}. A different {gitRefType} can be selected from the deployment process screen.
            </Note>);
    }
    private updateRuleProperty = <K extends keyof ChannelVersionRuleResource>(versionRuleIndex: number, propertyName: K, value: ChannelVersionRuleResource[K]) => {
        this.setState((state) => {
            const rules = [...state.model.Rules];
            rules[versionRuleIndex][propertyName] = value;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };
    private updateRule = (index: number, rule: ChannelVersionRuleResource) => {
        this.setState((state) => {
            const rules = [...state.model.Rules];
            rules[index] = rule;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };
    private throttledVersionRange(idx: number, value: string) {
        this.updateRuleProperty(idx, "VersionRange", value);
    }
    private throttledTag(idx: number, value: string) {
        this.updateRuleProperty(idx, "Tag", value);
    }
    private descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description}/>) : Summary.placeholder("No channel description provided");
    }
    private deleteChannel = async () => {
        return this.doBusyTask(async () => {
            await repository.Channels.del(this.state.model);
            this.setState({ redirectTo: links.channelsPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug }) });
        });
    };
    private saveChannel = async () => {
        await this.doBusyTask(async () => {
            const actionEvent: ActionEvent = {
                action: Action.Save,
                resource: "Channel",
            };
            await this.props.trackAction("Save Channel", actionEvent, async () => {
                const result = await repository.Channels.saveToProject(this.props.project, this.state.model);
                if (this.props.channel === "create new channel") {
                    this.setState({ redirectTo: links.channelsPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug }) });
                }
                else {
                    this.setState({
                        model: result,
                        cleanModel: _.cloneDeep(result),
                    });
                }
            });
        });
    };
    static displayName = "CreateOrEditChannel";
}
async function getDeploymentActionsWithErrorHandlingForGitProjects(project: ProjectResource, repository: DeploymentProcessRepository, gitRefValidationError: ValidateGitRefV2Response | undefined): Promise<DeploymentActionResource[] | OctopusError> {
    try {
        if (gitRefValidationError) {
            return [];
        }
        else {
            const process = await repository.get();
            return _.flatMap(process.Steps, (step) => step.Actions);
        }
    }
    catch (exception) {
        if (HasGitPersistenceSettings(project.PersistenceSettings) && OctopusError.isOctopusError(exception)) {
            // We only want to catch the error if it's a Git project and an Octopus exception. Otherwise, error
            // as usual
            return exception;
        }
        else {
            throw exception;
        }
    }
}
