import { BooleanRadioButton, BooleanRadioButtonGroup, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { ProjectResource, EnvironmentResource, RunbookProcessResource, RunbookResource, ConnectivityPolicy, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import RunbooksNavigationTabs from "~/areas/projects/components/Runbooks/RunbooksNavigationTabs";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { RoleChip } from "~/components/Chips";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, MarkdownEditor, Summary, Text, required, Note, FormSectionHeading } from "~/components/form";
import FailureMode from "../../../Releases/Deployments/FailureMode";
import { getFormattedCommitMessage } from "../../../VersionControl/CommitMessageWithDetails";
import { useCommitButton } from "../../../VersionControl/useCommitButton";
import AddRunbook from "../../AddRunbook";
import DeleteRunbook from "../../DeleteRunbook";
import { RunbooksPaperLayout } from "../../Layouts";
import RunNowButton from "../../RunNowButton";
import { useRunbookContext } from "../../RunbookContext";
import RunbookEnvironmentScopeSelector from "../../RunbookEnvironmentScopeSelector";
import { RunbookRetentionPolicyControl } from "../../RunbookRetentionPolicy";
import { DeploymentModelType } from "../../RunbookRunNowLayout";
interface RunbookSettingsPageProps {
    runbookId: string;
}
interface RunbookSettingsPageInternalProps extends RunbookSettingsPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
    getFieldError: (fieldName: string) => string;
}
export class RunbookSettingsPage extends DataBaseComponent<RunbookSettingsPageProps> {
    constructor(props: RunbookSettingsPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <RunbookSettingsPageInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} getFieldError={this.getFieldError} {...this.props}/>;
    }
    static displayName = "RunbookSettingsPage";
}
const Title = "Settings";
const isMultiTenancyEnabledSelector = (state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled;
function RunbookSettingsPageInternal({ doBusyTask, busy, errors, getFieldError, runbookId }: RunbookSettingsPageInternalProps) {
    const isMultiTenancyEnabled = useSelector(isMultiTenancyEnabledSelector);
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const gitRef = projectContext.state.gitRef;
    const [runbook, setRunbook] = useState<RunbookResource>();
    const [cleanRunbook, setCleanRunbook] = useState<RunbookResource>();
    const [runbookProcess, setRunbookProcess] = useState<RunbookProcessResource>();
    const [machineRoles, setMachineRoles] = useState<string[]>();
    const [environments, setEnvironments] = useState<EnvironmentResource[]>();
    const [redirectTo, setRedirectTo] = useState<LinkHref>();
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const isGitRunbook = HasRunbooksInGit(project.PersistenceSettings) && !!gitRef;
    const [commitAction, commitMessage] = useCommitButton(isGitRunbook, project, gitRef, "runbook", saveChangesWithNewBranch);
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const machineRoles = repository.MachineRoles.all();
        const environments = repository.Environments.all();
        setMachineRoles(await machineRoles);
        setEnvironments(await environments);
    }, []);
    async function loadRunbooksAndSteps() {
        if (isGitRunbook) {
            const runbook = await repository.Runbooks.getRunbook(project, runbookId, gitRef?.CanonicalName);
            const process = await repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId, gitRef?.CanonicalName);
            return { runbook, process };
        }
        else {
            const runbook = await repository.Runbooks.getRunbook(project, runbookId);
            const process = await repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId);
            return { runbook, process };
        }
    }
    useDoBusyTaskEffect(doBusyTask, async () => {
        const { runbook, process } = await loadRunbooksAndSteps();
        setRunbook(runbook);
        setCleanRunbook(runbook);
        setRunbookProcess(process);
    }, [runbookId, gitRef]);
    if (redirectTo) {
        return <InternalRedirect to={redirectTo}/>;
    }
    if (!project) {
        throw new Error("Project must be loaded.");
    }
    if (!runbook || !cleanRunbook || !runbookProcess || !machineRoles || !environments) {
        const title = runbookContext.state.runbook?.Name || Title;
        return <RunbooksPaperLayout title={title} busy={busy} errors={errors}/>;
    }
    const legacyOverflowActions = GetOverflowActions(runbook, project, gitRef, canDelete, setCanDelete, setRedirectTo, (project: ProjectResource) => projectContext.actions.onProjectUpdated(project, undefined));
    const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
    return (<Form model={runbook} cleanModel={cleanRunbook} onSaveClick={saveChangesDefault} savePermission={{
            permission: Permission.RunbookEdit,
            project: project.Id,
            wildcard: true,
        }} saveText="Runbook updated" confirmNavigateSaveLabel={`Save changes`}>
            {({ FormContent, createSaveAction, save, isDisabled }) => (<PaperLayoutVNext busy={busy} errors={errors} title={runbook.Name} breadcrumbsItems={[{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} pageActions={[
                {
                    type: "custom",
                    content: <RunNowButton spaceId={runbook.SpaceId} isDisabled={runbookProcess.Steps.length === 0}/>,
                    key: "Run Now",
                    hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }),
                },
            ]} overflowActions={overflowMenu.menuItems} primaryAction={commitAction ? commitAction({ isDisabled, onClick: () => save() }) : createSaveAction({})} titleAccessory={<ProjectPageTitleAccessory />} disableAnimations={true} fullWidth={true}>
                    <RunbooksNavigationTabs />
                    <FormContent>
                        <>
                            {overflowMenu.dialogs}
                            <GitCallouts isRunbooksPage/>
                            <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={runbook.Name ? Summary.summary(runbook.Name) : Summary.placeholder("Please enter a name for the Runbook")} help="Enter a name for the Runbook">
                                <Text value={runbook.Name} onChange={(Name) => setRunbookState({ Name })} label="Name" validate={required("Please enter a name")} error={getFieldError("Name")} autoFocus={true}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="Description" title="Description" summary={runbook.Description ? Summary.summary(<Markdown markup={runbook.Description}/>) : Summary.placeholder("No description provided")} help="Enter a description for the Runbook">
                                <MarkdownEditor value={runbook.Description} label="Description" onChange={(Description) => setRunbookState({ Description })}/>
                            </ExpandableFormSection>
                            <FormSectionHeading title="Run Settings"/>
                            <RunbookEnvironmentScopeSelector environments={environments} environmentScope={runbook.EnvironmentScope} inclusiveEnvironments={runbook.Environments} onEnvironmentScopeChanged={(EnvironmentScope) => setRunbookState({ EnvironmentScope })} onEnvironmentsChanged={(Environments) => setRunbookState({ Environments })}/>
                            {
            // eslint-disable-next-line: max-line-length
            (isMultiTenancyEnabled || cleanRunbook.MultiTenancyMode !== TenantedDeploymentMode.Untenanted) && isAllowed({ permission: Permission.TenantView, tenant: "*", project: project.Id }) && (<ExpandableFormSection errorKey="tenantedDeploymentMode" title="Multi-tenancy" summary={tenantedDeploymentModeSummary(runbook)} help="Choose whether this runbook runs against a tenant.">
                                        <RadioButtonGroup value={runbook.MultiTenancyMode} onChange={(tenantedDeploymentMode) => setRunbookState({ MultiTenancyMode: tenantedDeploymentMode })}>
                                            <RadioButton value={TenantedDeploymentMode.Untenanted} label="Runbook cannot be run against a tenant" isDefault={true}/>
                                            <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Runbook can be run with or without a tenant"/>
                                            <RadioButton value={TenantedDeploymentMode.Tenanted} label="Runbook must be run against a tenant"/>
                                        </RadioButtonGroup>
                                        <Note>
                                            <ExternalLink href="ProjectTenantedDeploymentMode">Learn more about tenanted deployment modes</ExternalLink>
                                        </Note>
                                    </ExpandableFormSection>)}
                            {/* TODO Runbooks: Component */}
                            <ExpandableFormSection errorKey="skipMachines" title="Deployment Target Status" summary={skipMachinesSummary(runbook.ConnectivityPolicy)} help="Choose to skip unavailable, or exclude unhealthy targets when running the runbook.">
                                <RadioButtonGroup title="Unavailable Deployment targets" value={runbook.ConnectivityPolicy.SkipMachineBehavior} onChange={(skipMachines) => handleSkipMachinesChanged(runbook, skipMachines)}>
                                    <RadioButton value="None" label="Do not skip, and fail" isDefault={true}/>
                                    <RadioButton value="SkipUnavailableMachines" label="Skip"/>
                                    <Note>Deployment targets that are unavailable at the start of the run or become unavailable during the run will be skipped and removed from the run.</Note>
                                </RadioButtonGroup>
                                {runbook.ConnectivityPolicy.SkipMachineBehavior === "SkipUnavailableMachines" && (<div>
                                        <TargetTagMultiSelect onChange={(skipMachinesRoles) => setConnectivityPolicyState({ TargetRoles: skipMachinesRoles })} value={runbook.ConnectivityPolicy.TargetRoles} label="Skip unavailable deployment targets only with selected tags" items={machineRoles}/>
                                        <Note>By default, deployment targets with any tag will be skipped if they are unavailable, to limit to certain tags select them here.</Note>
                                    </div>)}
                                <RadioButtonGroup value={runbook.ConnectivityPolicy.ExcludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"} onChange={(skipUnhealthyTargets) => setConnectivityPolicyState({ ExcludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })} title="Unhealthy Deployment Targets">
                                    <RadioButton value="None" label="Do not exclude" isDefault={true}/>
                                    <RadioButton value="ExcludeUnhealthy" label="Exclude"/>
                                    <Note>Deployment targets that are unhealthy at the start of the run will be skipped and removed from the run.</Note>
                                </RadioButtonGroup>
                            </ExpandableFormSection>
                            <FailureMode guidedFailureMode={runbook.DefaultGuidedFailureMode} onModeChanged={(DefaultGuidedFailureMode) => setRunbookState({ DefaultGuidedFailureMode })} title="Default Failure Mode" modelType={DeploymentModelType.Runbook}/>
                            <RunbookRetentionPolicyControl retentionPolicy={runbook.RunRetentionPolicy} onRetentionPolicyChange={(RunRetentionPolicy) => setRunbookState({ RunRetentionPolicy })}/>

                            <ExpandableFormSection errorKey="forcePackageDownload" title="Force Package Download" summary={forcePackageDownloadSummary(runbook)} help="Choose whether to force re-downloading packages">
                                <BooleanRadioButtonGroup accessibleName="Force package download" value={runbook.ForcePackageDownload} onChange={(forcePackageDownload) => setRunbookState({ ForcePackageDownload: forcePackageDownload })}>
                                    <BooleanRadioButton value={false} label="Use cached packages (if available)" isDefault={true}/>
                                    <BooleanRadioButton value={true} label="Re-download packages from feed"/>
                                </BooleanRadioButtonGroup>
                                <Note>Sets a default package download option</Note>
                            </ExpandableFormSection>
                        </>
                    </FormContent>
                </PaperLayoutVNext>)}
        </Form>);
    // TODO Runbooks: extract and reuse w/ProjectSettings
    function skipMachinesSummary(connectivityPolicy: ConnectivityPolicy): SummaryNode {
        // TODO: convert to enum
        if (connectivityPolicy.SkipMachineBehavior !== "SkipUnavailableMachines") {
            return connectivityPolicy.ExcludeUnhealthyTargets ? Summary.summary("Runbook will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Runbook will fail if a target is unavailable");
        }
        const roles = connectivityPolicy.TargetRoles;
        const summary = [connectivityPolicy.ExcludeUnhealthyTargets ? <span key="skipMachines">Runbook will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Runbook will skip unavailable targets</span>];
        if (roles.length > 0) {
            summary.push(connectivityPolicy.TargetRoles.length > 1 ? <span> with tags</span> : <span> with tag</span>);
            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r}/>);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }
    function tenantedDeploymentModeSummary(runbook: RunbookResource): SummaryNode {
        switch (runbook.MultiTenancyMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default("Runbook cannot be run against a tenant");
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return Summary.summary("Runbook can be run with or without a tenant");
            case TenantedDeploymentMode.Tenanted:
                return Summary.summary("Runbook must be run against a tenant");
            default:
                return Summary.placeholder("Please select");
        }
    }
    function setRunbookState(state: Partial<RunbookResource>) {
        setRunbook((existingRunbook) => {
            if (!existingRunbook) {
                throw new Error("Runbook must be loaded.");
            }
            return { ...existingRunbook, ...state };
        });
    }
    function setConnectivityPolicyState(state: Partial<ConnectivityPolicy>) {
        setRunbook((existingRunbook) => {
            if (!existingRunbook) {
                throw new Error("Runbook must be loaded.");
            }
            return {
                ...existingRunbook,
                ConnectivityPolicy: {
                    ...existingRunbook.ConnectivityPolicy,
                    ...state,
                },
            };
        });
    }
    function handleSkipMachinesChanged(runbook: RunbookResource, skipMachines: string) {
        setConnectivityPolicyState({
            SkipMachineBehavior: skipMachines,
            TargetRoles: skipMachines === "None" ? [] : runbook.ConnectivityPolicy.TargetRoles,
        });
    }
    async function saveChangesWithNewBranch(newBranch: GitRefResource) {
        await saveChanges(newBranch);
        projectContext.actions.changeGitRef(newBranch.CanonicalName);
    }
    async function saveChangesDefault() {
        await saveChanges();
    }
    async function saveChanges(newBranch?: GitRefResource) {
        return await doBusyTask(async () => {
            const savedRunbook = await saveRunbook(newBranch);
            setRunbook(savedRunbook);
            setCleanRunbook(savedRunbook);
            runbookContext.actions.onRunbookUpdated(savedRunbook);
        });
    }
    async function saveRunbook(newBranch?: GitRefResource) {
        if (!runbook) {
            throw new Error("Runbook must be loaded.");
        }
        const isGitRunbook = HasRunbooksInGit(project.PersistenceSettings);
        if (isGitRunbook) {
            const formattedCommitMessage = getFormattedCommitMessage(commitMessage, "Modify runbook");
            const ref = newBranch?.CanonicalName || gitRef?.CanonicalName;
            return repository.Runbooks.modifyRunbook(runbook, ref, formattedCommitMessage);
        }
        else {
            return repository.Runbooks.modifyRunbook(runbook);
        }
    }
    function forcePackageDownloadSummary(runbook: RunbookResource): SummaryNode {
        return runbook.ForcePackageDownload ? Summary.summary("A package will be re-downloaded from feed") : Summary.default("Use cached packages (if available)");
    }
}
function GetOverflowActions(runbook: RunbookResource, project: ProjectResource, gitRef: GitRefResource | undefined, canDelete: boolean, setCanDelete: (canDelete: boolean) => void, setRedirectTo: (link: LinkHref) => void, onProjectUpdated: (project: ProjectResource) => Promise<void>) {
    async function handleDeleteConfirm() {
        const isGitRunbook = HasRunbooksInGit(project.PersistenceSettings) && gitRef;
        if (isGitRunbook) {
            await repository.Runbooks.deleteRunbook(runbook, gitRef?.CanonicalName);
        }
        else {
            await repository.Runbooks.deleteRunbook(runbook);
        }
        await onProjectUpdated(project); // Update project summary
        setRedirectTo(links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
        return true;
    }
    const overFlowActions = [];
    overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog runbook={runbook} project={project}/>, {
        permission: Permission.RunbookEdit,
        projectGroup: project.ProjectGroupId,
        wildcard: true,
    }));
    overFlowActions.push(OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this Runbook?", handleDeleteConfirm, (dialogDoBusyTask) => <DeleteRunbook runbookName={runbook.Name} onChange={setCanDelete}/>, {
        permission: Permission.RunbookEdit,
        project: project.Id,
        wildcard: true,
    }, !canDelete));
    overFlowActions.push(OverflowMenuItems.navItem("View Snapshots", links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }), {
        permission: Permission.RunbookView,
        project: project.Id,
        projectGroup: project.ProjectGroupId,
        wildcard: true,
    }));
    overFlowActions.push([
        OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }), {
            permission: Permission.EventView,
            wildcard: true,
        }),
    ]);
    return overFlowActions;
}
interface CloneDialogProps {
    runbook: RunbookResource;
    project: ProjectResource;
}
function CloneDialog({ runbook, project }: CloneDialogProps) {
    const navigation = useSpaceAwareNavigation();
    return (<AddRunbook projectId={project.Id} onProcessCreated={async (id) => {
            navigation.navigate(links.projectRunbookOverviewPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: id }));
        }} cloneId={runbook.Id}/>);
}
