import type { Event as AnalyticsEvent } from "@amplitude/analytics-types";
import { Environment } from "@octopusdeploy/utilities";
import React from "react";
import { TaskLogViewer } from "~/areas/configuration/components/Diagnostics/TaskLogViewer";
import { client } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { AmplitudeEventsTab } from "~/components/DevTools/AmplitudeEventsTab/AmplitudeEventsTab";
import { useRegisteredDevToolTabs } from "~/components/DevTools/DevToolsContext";
import { Images } from "~/components/DevTools/Images/Images";
import { useProfilerCaching } from "~/components/DevTools/MiniProfiler/ProfilerCache/index";
import { MiniProfilerPanel } from "~/components/DevTools/MiniProfiler/index";
import { SiteMapTab } from "~/components/DevTools/SiteMapTab";
import ToggleControls from "~/components/DevTools/Toggles/ToggleControls";
import { PermissionsTab } from "./Permissions/PermissionsTab";
interface Tab {
    label: string; // This label is used as a key, so it is assumed to be globally unique across all tabs
    content: React.ReactNode;
}
export function useDevDrawerTabs(doBusyTask: DoBusyTask, analyticsEvents: AnalyticsEvent[]) {
    const dynamicTabs = useRegisteredDevToolTabs();
    return [createAmplitudeEventsTab(analyticsEvents), togglesTab, permissionsTab, siteMapTab, imagesTab, ...dynamicTabs, ...useDevelopmentModeOnlyTabs(doBusyTask)];
}
const togglesTab: Tab = {
    label: "Toggles",
    content: <ToggleControls />,
};
const permissionsTab: Tab = {
    label: "Permissions",
    content: <PermissionsTab />,
};
const imagesTab: Tab = {
    label: "Images",
    content: <Images />,
};
const taskLogViewerTab: Tab = {
    label: "Task Log Viewer",
    content: <TaskLogViewer />,
};
const siteMapTab: Tab = {
    label: "Sitemap",
    content: <SiteMapTab />,
};
function createAmplitudeEventsTab(analyticsEvents: AnalyticsEvent[]): Tab {
    return {
        label: "Amplitude Events",
        content: <AmplitudeEventsTab analyticsEvents={analyticsEvents}/>,
    };
}
function useDevelopmentModeOnlyTabs(doBusyTask: DoBusyTask): Tab[] {
    const { pagedRequests, actions, isCapturing } = useProfilerCaching(client, doBusyTask);
    if (Environment.isInDevelopmentMode()) {
        return [
            {
                label: "Profiler",
                content: <MiniProfilerPanel pageSessions={pagedRequests} actions={actions} enabled={isCapturing}/>,
            },
            taskLogViewerTab,
        ];
    }
    return [];
}
