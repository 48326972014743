import type { GitRefResource, ProjectResource, Repository, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ChannelLookupData } from "~/areas/projects/components/Channels/CreateOrEditChannel";
import { channelLookupDataLoader, CreateOrEditChannel } from "~/areas/projects/components/Channels/CreateOrEditChannel";
import { type ProjectContextProps } from "~/areas/projects/context/index";
export async function createChannelPageLoader(repository: Repository, projectContext: Promise<ProjectContextProps>): Promise<ChannelPageLoaderData> {
    const lookupData = channelLookupDataLoader(repository, projectContext);
    return {
        lookupData: await lookupData,
    };
}
interface ChannelPageLoaderData {
    lookupData: ChannelLookupData;
}
interface CreateChannelPageProps {
    loaderData: ChannelPageLoaderData;
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
}
export function CreateChannelPage({ loaderData, project, gitRef, gitRefValidationError }: CreateChannelPageProps) {
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(project.Id);
    return <CreateOrEditChannel channel="create new channel" trackAction={trackAction} lookupData={loaderData.lookupData} pageTitle={createChannelPageTitle} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError}/>;
}
export const createChannelPageTitle = "New Channel";
