/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Divider, useIsLargerThanIpadResolution } from "@octopusdeploy/design-system-components";
import { themeTokens, text, space } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type { ReactNode } from "react";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { RouteAwareErrorBoundary } from "~/components/ErrorBoundary/index";
import { useSetInPageNavVisible } from "~/components/GlobalLayout/InPageNavVisibilityContext";
import type { MenuNodeItem } from "~/components/LinksMenu/MenuNode";
import Markdown from "~/components/Markdown/index";
import InternalNavLink from "~/components/Navigation/InternalNavLink/InternalNavLink";
import type { NavLink } from "~/components/NavigationSidebarLayout/index";
import styles from "~/components/NavigationSidebarLayout/style.module.less";
import PriorityNavigation from "~/components/PriorityNavigation/index";
export type NavDivider = "divider";
export type NavItemVNext = NavLink | NavDivider;
interface PortalPageLayoutProps {
    navItems?: NavItemVNext[];
    content: ReactNode;
    header?: ReactNode;
    callout?: ReactNode;
    tourProgress?: ReactNode;
    description?: string;
}
export function PortalPageLayout({ navItems = [], content, description, header, callout, tourProgress }: PortalPageLayoutProps) {
    const setIsInPageNavVisible = useSetInPageNavVisible();
    const isLargerThanDesktop = useMediaQuery({ query: `(min-width: 1279px)` });
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const hasItems = navItems.length > 0;
    React.useEffect(() => {
        setIsInPageNavVisible?.(hasItems);
        return () => setIsInPageNavVisible?.(false);
    }, [hasItems, setIsInPageNavVisible]);
    const desktopView = (<div className={sideBarLayoutStyles}>
            <AnalyticLinkLocationProvider location="Sidebar navigation">
                {hasItems && (<nav className={sideNavBarStyles}>
                        {tourProgress}
                        <div className={sideNavBarLinksContainerStyles}>
                            {navItems.map((item, index) => (<div key={index}>{renderSideNavItem(item)}</div>))}
                        </div>
                        {description && (<div className={descriptionStyles}>
                                <Markdown markup={description}/>
                            </div>)}
                    </nav>)}
            </AnalyticLinkLocationProvider>
            <div className={cx(sideBarLayoutContentStyles, {
            [sideBarLayoutContentNoSidebarStyles]: !hasItems,
        })} key="content">
                <RouteAwareErrorBoundary>{content}</RouteAwareErrorBoundary>
            </div>
        </div>);
    const ipadView = (<div className={sideBarLayoutContentMobileStyles}>
            <div className={isLargerThanIpad ? resumeTourButtonIpadContainerStyles : resumeTourButtonMobileContainerStyles}>{tourProgress}</div>
            {hasItems && (<div>
                    <AnalyticLinkLocationProvider location="Sidebar navigation">
                        <PriorityNavigation className={styles.horizontalLinks} activeItemClassName={styles.selected} maxNavigationItems={99} navigationItems={navItems.filter(isNavLink).map(convertNavLinkToMenuNodeItem)}/>
                    </AnalyticLinkLocationProvider>
                </div>)}
            <div key="content">
                <RouteAwareErrorBoundary>{content}</RouteAwareErrorBoundary>
            </div>
        </div>);
    return (<div className={layoutContainerStyles}>
            <div className={contentContainerStyles}>
                {header}
                {callout}
                {isLargerThanDesktop ? desktopView : ipadView}
            </div>
        </div>);
}
function renderSideNavItem(item: NavItemVNext) {
    return isNavLink(item) ? (renderSideNavLink(item)) : (<div className={dividerWrapper}>
            <Divider />
        </div>);
}
function renderSideNavLink({ label, path, exact }: NavLink) {
    return (<InternalNavLink to={path} activeClassName={selectedLinkStyles} className={linkStyles} exact={exact}>
            {label}
        </InternalNavLink>);
}
function convertNavLinkToMenuNodeItem(navLink: NavLink): MenuNodeItem {
    return { url: navLink.path, text: navLink.label as string, exact: navLink.exact };
}
function isNavLink(item: NavItemVNext): item is NavLink {
    return item !== "divider";
}
const layoutContainerStyles = css({
    flex: 1,
    display: "flex",
    justifyContent: "center",
});
const contentContainerStyles = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
});
const sideBarLayoutStyles = css({
    flex: 1,
    display: "flex",
});
const sideNavBarStyles = css({
    color: themeTokens.color.text.primary,
    font: text.regular.default.medium,
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    gap: space[16],
    padding: `${space["16"]} ${space["16"]} ${space["24"]} ${space["32"]}`,
    width: "15rem",
});
const sideNavBarLinksContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["2"],
});
const dividerWrapper = css({
    padding: `${space["16"]} 0`,
    marginLeft: space["8"],
    maxWidth: "5.125rem",
});
const descriptionStyles = css({
    padding: `${space["24"]} ${space["8"]} ${space["24"]} ${space["8"]}`,
    overflowWrap: "break-word", // The description can contain words that are too long to fit in the sidebar, we should break these words to avoid overflowing
    font: text.regular.default.small,
});
const sideBarLayoutContentStyles = css({
    flex: "1",
    padding: `${space["16"]} ${space["32"]} ${space["16"]} 0`,
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    minWidth: 0,
});
const sideBarLayoutContentNoSidebarStyles = css({
    padding: `${space["16"]} ${space["32"]} ${space["16"]} ${space["32"]}`,
});
const sideBarLayoutContentMobileStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
});
const linkStyles = css({
    color: themeTokens.color.navList.text.default,
    display: "block",
    padding: `${space["4"]} ${space["8"]} ${space["4"]} ${space["12"]}`,
    borderRadius: space["4"],
    ":hover": {
        color: themeTokens.color.navigation.link.text.hover,
        backgroundColor: themeTokens.color.navigation.link.background.hover,
    },
    ":active": {
        color: themeTokens.color.navigation.link.text.pressed,
        background: themeTokens.color.navigation.link.background.pressed,
    },
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focused,
        outline: "none",
    },
});
const selectedLinkStyles = css({
    color: themeTokens.color.navList.text.active,
    background: buildBackgroundGradient(themeTokens.color.border.selected, themeTokens.color.navList.background.active),
    ":hover": {
        // We need to re-apply the same styles here because we have global css trying to style this hover state
        color: themeTokens.color.navList.text.active,
        background: buildBackgroundGradient(themeTokens.color.border.selected, themeTokens.color.navList.background.active),
    },
});
// This creates a background with a solid border on the left edge
function buildBackgroundGradient(borderColor: string, backgroundColor: string) {
    return `linear-gradient(90deg, ${borderColor} 0%, ${borderColor} ${space[4]}, ${backgroundColor} ${space[4]}, ${backgroundColor} 100%)`;
}
const resumeTourButtonIpadContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["32"]}`,
});
const resumeTourButtonMobileContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["16"]}`,
});
