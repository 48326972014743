/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { MuiThemeProvider } from "@material-ui/core/styles";
import type { TextThemeName, ThemeName } from "@octopusdeploy/design-system-components";
import { useApplyGlobalThemeEffect, Theme as DesignSystemTheme, useDesignSystemTheme } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ReactNode } from "react";
import * as React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { createMuiTheme } from "~/theme";
import { ThemeApplier } from "./ThemeApplier";
import type { OctopusTheme } from "./Themes";
import { DarkTheme, LightTheme } from "./Themes";
type LegacyThemeName = "light" | "dark";
type ThemeProps = React.PropsWithChildren<{
    palette: LegacyThemeName;
}>;
function Theme({ palette, children }: ThemeProps) {
    //TODO: FEF - Now that vertical navigation is the default, the v2 palette can become the new palette
    const themeName: ThemeName = `${palette}V2`;
    const textThemeName: TextThemeName = "text";
    useApplyGlobalThemeEffect(themeName, textThemeName);
    React.useEffect(() => {
        logger.info("Theme palette set to {themeName}", { themeName });
    }, [themeName]);
    return (<DesignSystemTheme themeName={themeName} isFullHeight={true}>
            <ThemeApplier />
            <MuiThemeApplier themeName={palette}>{children}</MuiThemeApplier>
        </DesignSystemTheme>);
}
function MuiThemeApplier({ themeName, children }: {
    themeName: LegacyThemeName;
    children: ReactNode;
}) {
    const legacyTheme = getLegacyTheme(themeName);
    const designSystemTheme = useDesignSystemTheme();
    const muiTheme = React.useMemo(() => createMuiTheme(themeName, legacyTheme, designSystemTheme), [themeName, legacyTheme, designSystemTheme]);
    return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
}
function getLegacyTheme(themeName: LegacyThemeName): OctopusTheme {
    return themeName === "light" ? LightTheme : DarkTheme;
}
function withTheme(render: (theme: OctopusTheme) => React.ReactElement | null | undefined): React.ReactElement {
    return <UsingTheme>{(theme) => render(theme)}</UsingTheme>;
}
type UsingThemeProps = {
    children: (theme: OctopusTheme) => React.ReactNode;
};
function UsingTheme({ children }: UsingThemeProps) {
    const theme = useOctopusTheme();
    return <>{children(theme)}</>;
}
function useOctopusTheme(): OctopusTheme {
    const palette = useThemePaletteType();
    return getLegacyTheme(palette);
}
export default Theme;
export { Theme, withTheme, useOctopusTheme };
export type { OctopusTheme };
