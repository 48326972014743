import type { ChannelResource, GitRefResource, ProjectResource, Repository, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ChannelLookupData } from "~/areas/projects/components/Channels/CreateOrEditChannel";
import { channelLookupDataLoader, CreateOrEditChannel } from "~/areas/projects/components/Channels/CreateOrEditChannel";
import { type ProjectContextProps } from "~/areas/projects/context/index";
export async function editChannelPageLoader(repository: Repository, channelId: string, projectContext: Promise<ProjectContextProps>): Promise<EditChannelPageLoaderData> {
    const lookupData = channelLookupDataLoader(repository, projectContext);
    const { state: { model: project }, } = await projectContext;
    const channels = await repository.Projects.getChannels(project);
    const channel = channels.Items.filter((c) => c.Id === channelId)[0];
    return {
        lookupData: await lookupData,
        channel,
    };
}
export interface EditChannelPageLoaderData {
    lookupData: ChannelLookupData;
    channel: ChannelResource;
}
interface EditChannelPageProps {
    loaderData: EditChannelPageLoaderData;
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
}
export function EditChannelPage({ loaderData, project, gitRef, gitRefValidationError }: EditChannelPageProps) {
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(project.Id);
    return <CreateOrEditChannel channel={loaderData.channel} trackAction={trackAction} lookupData={loaderData.lookupData} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError}/>;
}
