import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import type { WelcomeDialogStates } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialog";
import { WelcomeDialogActionCards } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialogActionCards";
import { WelcomeDialogHelpPanelContent } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialogHelpPanelContent";
import { repository } from "~/clientInstance";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { DataBaseComponentState, DoBusyTask, Errors } from "../DataBaseComponent";
import DataBaseComponent from "../DataBaseComponent";
import NewProjectWizard from "../ProjectBasedActivation/NewProjectWizard";
import { GuidedSetupFrame } from "./GuidedSetupFrame";
type WelcomeToOctopusInternalProps = {
    onCreateSampleProject: (sampleProjectReference: string) => Promise<ProjectResource | undefined>;
    currentSpace: SpaceResource;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    errors: Errors | undefined;
    onDialogClosed: () => void;
    dismissed: boolean;
};
export function WelcomeToOctopusInternal(props: WelcomeToOctopusInternalProps) {
    const [activeDialog, setActiveDialog] = React.useState<WelcomeDialogStates>("none");
    const navigation = useSpaceAwareNavigation();
    if (props.dismissed) {
        return null;
    }
    if (activeDialog === "new-project-dialog") {
        return (<NewProjectWizard open={true} fullScreen={true} busy={props.busy} doBusyTask={props.doBusyTask} spaceId={props.currentSpace.Id} saveProjectOptions={true} close={() => {
                props.onDialogClosed();
                setActiveDialog("none");
            }} back={() => {
                setActiveDialog("none");
            }}/>);
    }
    const onCloseDialogClicked = () => {
        props.onDialogClosed();
        navigation.navigate(links.projectsPage.generateUrl({ spaceId: props.currentSpace.Id }));
    };
    const onWelcomeDialogCardClicked = (value: WelcomeDialogStates) => {
        setActiveDialog(value);
        if (value !== "new-project-dialog") {
            props.onDialogClosed();
        }
    };
    if (activeDialog === "none") {
        return (<GuidedSetupFrame onClose={onCloseDialogClicked} helpPanelContent={<WelcomeDialogHelpPanelContent />} showThemeSelector={true}>
                <h1 className={styles.heading}>Welcome to Octopus</h1>
                <span className={styles.subheading}>Simple, reliable and auditable deployments</span>

                <p className={styles.callToAction}>Where would you like to start?</p>

                <WelcomeDialogActionCards onCreateSampleProject={props.onCreateSampleProject} busy={props.busy} currentSpace={props.currentSpace} onChange={onWelcomeDialogCardClicked}/>
                <div>
                    <Button onClick={onCloseDialogClicked} label="Skip Project Setup" importance="quiet"/>
                </div>
            </GuidedSetupFrame>);
    }
    return null;
}
const styles = {
    heading: css({
        marginTop: "92px",
        marginBottom: 0,
        font: text.heading.xLarge,
        fontWeight: 700,
    }),
    subheading: css({
        color: themeTokens.color.text.secondary,
    }),
    callToAction: css({
        marginTop: "40px",
    }),
};
type WelcomeToOctopusProps = {
    // TODO: Delete this variable along with KubernetesGuidedSetupFeatureToggle and feature slug kubernetes-guided-setup
    // This variable is for development purposes and is used to force the welcome dialog to show.
    forceWelcomeDialog: boolean;
    onClose: () => void;
};
interface WelcomeToOctopusState extends DataBaseComponentState {
    userOnboarding?: UserOnboardingResource;
    currentSpace?: SpaceResource;
}
export class WelcomeToOctopus extends DataBaseComponent<WelcomeToOctopusProps, WelcomeToOctopusState> {
    constructor(props: WelcomeToOctopusProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId)
                return;
            const [response, space] = await Promise.all([repository.UserOnboarding.getForCurrentUser(), repository.Spaces.get(repository.spaceId)]);
            this.setState({ userOnboarding: response?.UserOnboardingResource, currentSpace: space });
        });
    }
    async handleCreateSampleProject(sampleProjectReference: string): Promise<ProjectResource | undefined> {
        let project: ProjectResource | undefined = undefined;
        await this.doBusyTask(async () => {
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            project = sampleProject.Project;
        });
        return project;
    }
    render() {
        const { currentSpace, userOnboarding } = this.state;
        if (!currentSpace) {
            return null;
        }
        return (<WelcomeToOctopusInternal currentSpace={currentSpace} busy={this.state.busy} doBusyTask={this.doBusyTask} errors={this.errors} onDialogClosed={this.props.onClose} onCreateSampleProject={(sampleProjectReference: string) => this.handleCreateSampleProject(sampleProjectReference)} dismissed={userOnboarding?.WelcomeDialogDismissedDate !== null && !this.props.forceWelcomeDialog}/>);
    }
    static displayName = "WelcomeToOctopus";
}
