/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton } from "@octopusdeploy/design-system-components";
import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import { GetNamedPackageReferences, isContainerImageRegistry, PackageAcquisitionLocation, DOCKER_IMAGE_REFERENCE_PURPOSE, PackageSelectionMode, GetPackageVariableNames } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import { CalloutModifiedFeedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutModifiedFeedTriggerConnections";
import type { RelatedTriggersDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { CalloutReferencedPackageFeedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { ProcessFeedLookup } from "../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import ListTitle from "../../primitiveComponents/dataDisplay/ListTitle";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import DialogOpener from "../Dialog/DialogOpener";
import ExternalLink from "../Navigation/ExternalLink";
import { RemoveItemsList } from "../RemoveItemsList/RemoveItemsList";
import { Note, Summary } from "../form";
import ExpandableFormSection from "../form/Sections/ExpandableFormSection";
import { default as FormSectionHeading } from "../form/Sections/FormSectionHeading";
import type { ActionWithFeeds } from "./commonActionHelpers";
import type { ActionEditProps } from "./pluginRegistry";
import { ScriptPackageReferenceDialog } from "./script/ScriptPackageReferenceDialog";
import type { ScriptPackageProperties, ScriptPackageReference } from "./script/ScriptPackageReferenceDialog";
class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {
}
type PackageReferenceListProps = ActionEditProps<{}, ScriptPackageProperties> & ActionWithFeeds & {
    relatedTriggersDependencies?: RelatedTriggersDependencies;
    modelDirty?: boolean;
    packageReferences: ScriptPackageReference[];
};
interface PackageReferenceListState {
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
}
export class ContainerImageReferenceList extends BaseComponent<PackageReferenceListProps, PackageReferenceListState> {
    constructor(props: PackageReferenceListProps) {
        super(props);
        this.state = {};
    }
    render() {
        const dialog = this.openDialog();
        const packageReferences = this.props.packageReferences;
        return (<>
                {dialog}
                <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={this.addPackageReference()}/>]} data={packageReferences} onRow={(p: ScriptPackageReference) => this.packageReferenceListItem(p)} onRowTouch={(pkg: ScriptPackageReference) => this.editPackageReference(pkg)} onRemoveRow={(pkg: ScriptPackageReference) => this.removePackageReference(pkg)}/>

                {this.props.relatedTriggersDependencies && <CalloutReferencedPackageFeedTriggerConnections dependencies={this.props.relatedTriggersDependencies} packageReferences={packageReferences}/>}
                {this.props.relatedTriggersDependencies && <CalloutModifiedFeedTriggerConnections dependencies={this.props.relatedTriggersDependencies} packageReferences={packageReferences} modelDirty={this.props.modelDirty ?? false}/>}
            </>);
    }
    private addPackageReference() {
        return () => {
            const additionalPackage: ScriptPackageReference = {
                Id: null!,
                Name: null!,
                FeedId: null!,
                PackageId: null!,
                Version: null!,
                // The packages are docker images to be acquired by the cluster, not the target
                AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
                Properties: {
                    Extract: "false",
                    Purpose: DOCKER_IMAGE_REFERENCE_PURPOSE,
                },
            };
            this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null! });
        };
    }
    private editPackageReference(pkg: ScriptPackageReference) {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    }
    private removePackageReference(pkg: ScriptPackageReference) {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    }
    private packageReferenceListItem(pkg: ScriptPackageReference) {
        return (<ProcessFeedLookup feedId={pkg.FeedId}>
                {(feed) => (<div>
                        <ListTitle>{pkg.Name}</ListTitle>
                        <div>
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<React.Fragment>
                                    <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                                </React.Fragment>)}
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                        </div>
                    </div>)}
            </ProcessFeedLookup>);
    }
    private resetSelectedPackageReference() {
        this.setState({
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        });
    }
    private async loadFeeds() {
        return await this.props.refreshFeeds();
    }
    private savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (!this.state.editPackageReferenceIndex) {
            packageReferences.push(packageReference);
        }
        else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }
    private packageVariableNames(): string[] {
        return _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => GetPackageVariableNames(pkg, this.props.feeds)));
    }
    private openDialog() {
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        return (<DialogOpener open={!!this.state.editPackageReference} onClose={() => this.resetSelectedPackageReference()}>
                <ScriptPackageReferenceDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} 
        // The docker images will be acquired by the k8s cluster, not by Octopus or targets
        feeds={this.props.feeds
                .filter((f) => isContainerImageRegistry(f.FeedType))
                .map((f) => {
                f.PackageAcquisitionLocationOptions = [PackageAcquisitionLocation.NotAcquired];
                return f;
            })} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={() => this.loadFeeds()} parameters={this.props.parameters}/>
            </DialogOpener>);
    }
    static displayName = "ContainerImageReferenceList";
}
type DockerReferenceListFormSectionProps = Omit<PackageReferenceListProps, "packageReferences" | "additionalPackageProperties">;
export function DockerReferenceListFormSection(props: DockerReferenceListFormSectionProps) {
    const { expandedByDefault, packages } = props;
    const getDockerImageReferences = () => {
        return GetNamedPackageReferences(packages).filter((p) => p.Properties.Purpose === DOCKER_IMAGE_REFERENCE_PURPOSE);
    };
    const packageReferences = getDockerImageReferences();
    const packageReferenceSummary = (packageReferences: PackageReference[]) => {
        if (packageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${packageReferences.length} package ${pluralize("reference", packageReferences.length)}`);
    };
    return (<div>
            <FormSectionHeading title="Docker image references"/>
            <ExpandableFormSection title={"Referenced Images"} isExpandedByDefault={expandedByDefault} errorKey="Octopus.Action.DockerImageReferences" summary={packageReferenceSummary(packageReferences)} help={"Add docker images to be referenced by your templates at execution-time"}>
                <Note>
                    Docker images referenced by this step have their version (tag) selected at release creation. Properties relating to the images are then accessible via special package variables. Learn more about{" "}
                    <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                </Note>
                <ContainerImageReferenceList {...props} packageReferences={packageReferences}/>
            </ExpandableFormSection>
        </div>);
}
