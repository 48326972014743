import type { GitPersistenceSettings, GitRefResource, ProjectResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useEffect, useState } from "react";
import { repository } from "~/clientInstance";
import { useProjectContext } from "../../context";
import { lastAccessedGitRef } from "../../context/LastAccessedGitRef";
export interface GitRefQueryParams {
    gitRef: string;
}
export interface GitRefQueryParamsProps {
    queryParams: GitRefQueryParams;
    setQueryParams: QueryParamValuesSetter<GitRefQueryParams>;
}
export function useProjectRunbooksGitRef(project: ProjectResource, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>) {
    return useProjectGitRef(project, queryParams, setQueryParams, (settings) => settings.ConversionState.RunbooksAreInGit);
}
export function useProjectGitRef(project: ProjectResource, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>, isGitEnabled?: (settings: GitPersistenceSettings) => boolean): [
    GitRefResource | undefined,
    (gitRef: string) => void
] {
    const projectContext = useProjectContext();
    const validatedGitRef = useValidatedGitRef(project, queryParams.gitRef);
    useEffect(() => {
        if (validatedGitRef && validatedGitRef.GitRef) {
            // If we do have a GitRef in the query parameters, but it's different to our validated one, update it.
            setQueryParams({ gitRef: validatedGitRef.GitRef.CanonicalName });
            // Keep the last accessed GitRef up to date
            lastAccessedGitRef.save(project, validatedGitRef.GitRef.CanonicalName);
            projectContext.setState((previous) => ({ ...previous, gitRef: validatedGitRef.GitRef }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, validatedGitRef]);
    if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
        return [undefined, () => { }];
    }
    if (isGitEnabled && !isGitEnabled(project.PersistenceSettings)) {
        // Caller can pass a callback to see if the project is actually Git
        // enabled or not. If they've given us one, evaluate it.
        return [undefined, () => { }];
    }
    if (!queryParams.gitRef) {
        // If we don't have the GitRef in the query parameter, fill it with the last accessed one.
        setQueryParams({ gitRef: lastAccessedGitRef.get(project) });
    }
    return [
        // GitRef will be undefined until we've fetched it
        validatedGitRef?.GitRef || undefined,
        (gitRef: string) => {
            setQueryParams({ gitRef });
        },
    ];
}
export function useValidatedGitRef(project: ProjectResource, gitRef: string) {
    const [validationResult, setValidationResult] = useState<ValidateGitRefV2Response>();
    useEffect(() => {
        const fetch = async () => {
            const validationResult = await repository.Projects.validateGitRef(project, gitRef);
            if (!stale) {
                setValidationResult(validationResult);
            }
        };
        if (!gitRef) {
            return;
        }
        let stale = false;
        fetch();
        return () => {
            stale = true;
        };
    }, [project, gitRef]);
    return validationResult;
}
