/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import { Button } from "@material-ui/core";
import { ActionButton, ActionButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { MarkdownProvider } from "~/components/form/MarkdownEditor/MarkdownProvider";
import type { TextInputRef, GlobalConnectedProps } from "~/components/form/VariableLookup/VariableLookup";
import { withVariableLookup } from "~/components/form/VariableLookup/VariableLookup";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import type FormFieldProps from "../FormFieldProps";
import styles from "./style.module.less";
interface MarkdownEditorProps extends FormFieldProps<string> {
    accessibleName?: string;
    autoFocus?: boolean;
    placeholder?: string;
    bottomNoteText?: string | null;
    restrictHeight?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideMarkdownToggle?: boolean;
    validate?(value: string): string;
    onValidate?(value: string): void;
    textInputRef?(input: TextInputRef | null): void;
}
interface MarkdownEditorState {
    error?: string;
    showExternalError: boolean;
    showMarkdownEditor: boolean;
}
//eslint-disable-next-line react/no-unsafe
export default class MarkdownEditor extends React.Component<MarkdownEditorProps, MarkdownEditorState> {
    static defaultProps: Partial<MarkdownEditorProps> = {
        autoFocus: false,
        bottomNoteText: null,
    };
    text: TextInput = undefined!;
    localStorageKeyForShowingMarkdownControls = "MarkdownEditor.showMarkdownControls";
    constructor(props: MarkdownEditorProps) {
        super(props);
        this.state = {
            showExternalError: true,
            showMarkdownEditor: this.showMarkdownControls(),
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: MarkdownEditorProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }
    showMarkdownControls() {
        if (localStorage.getItem(this.localStorageKeyForShowingMarkdownControls) !== null) {
            return localStorage.getItem(this.localStorageKeyForShowingMarkdownControls) === "true" ? true : false;
        }
        return false;
    }
    handleChange = (value: string) => {
        this.callValidateAndChange(value);
    };
    callValidateAndChange(value: string) {
        if (this.props.validate) {
            const validateResult = this.props.validate(value);
            this.setState({ error: validateResult });
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        this.setState({ showExternalError: false });
        this.props.onChange!(value);
    }
    getInputState = (): MarkdownProvider => {
        const value = this.props.value == null ? "" : this.props.value;
        const selection = this.text.getSelection();
        return new MarkdownProvider(value, selection.start ?? 0, selection.end ?? 0);
    };
    setInputState = (value: string, selectionStart: number, selectionEnd: number) => {
        const selection = {
            start: selectionStart,
            end: selectionEnd,
        };
        this.text.setValueAndSelection(selection, value);
        this.text.focus();
    };
    bold = () => {
        this.getInputState().insertBefore("**").insertAfter("**").emptySelectionText("Bold text").apply(this.setInputState);
    };
    italic = () => {
        this.getInputState().insertBefore("_").insertAfter("_").emptySelectionText("Italic text").apply(this.setInputState);
    };
    bullet = () => {
        this.getInputState().surroundWithNewlines().insertBefore("- ").emptySelectionText("List item").apply(this.setInputState);
    };
    number = () => {
        this.getInputState().surroundWithNewlines().insertBefore("1. ").emptySelectionText("List item").apply(this.setInputState);
    };
    quote = () => {
        this.getInputState().surroundWithNewlines().insertBefore("> ").insertAfterNewlineInSelection("> ").emptySelectionText("Quoted text").apply(this.setInputState);
    };
    code = () => {
        this.getInputState().surroundWithNewlines().insertBefore("```\n").insertAfter("\n```").emptySelectionText("Code").apply(this.setInputState);
    };
    link = () => {
        const url = prompt("Please enter a url for the link:", "http://");
        if (url) {
            this.getInputState()
                .insertBefore("[")
                .insertAfter("](" + url + ")")
                .emptySelectionText("Enter link description here")
                .apply(this.setInputState);
        }
    };
    image = () => {
        const url = prompt("Please enter a url for the image:", "http://");
        if (url) {
            this.getInputState()
                .insertBefore("![")
                .insertAfter("](" + url + ")")
                .emptySelectionText("Enter image description here")
                .apply(this.setInputState);
        }
    };
    render() {
        const { value, label, validate, error, onChange, onValidate, placeholder, textInputRef, bottomNoteText, accessibleName, hideMarkdownToggle = false, restrictHeight, ...otherProps } = this.props;
        const errorText = this.state.error ?? (this.state.showExternalError ? error : undefined);
        const val = value ? value : "";
        const markdownTitle = `${this.state.showMarkdownEditor ? "Hide" : "Show"} markdown controls`;
        const showMarkdownControlsToggle = hideMarkdownToggle ? null : (<ActionButton label={markdownTitle} type={ActionButtonType.Ternary} className={styles.markdownControlsLink} onClick={(e) => {
                e.preventDefault();
                this.setState({ showMarkdownEditor: !this.state.showMarkdownEditor }, () => {
                    localStorage.setItem(this.localStorageKeyForShowingMarkdownControls, this.state.showMarkdownEditor.toString());
                });
            }}/>);
        return (<div className={cn(styles.markdownEditorContainer, restrictHeight && styles.restrictedHeight)}>
                {this.state.showMarkdownEditor && this.createButtons()}
                <Text textInputRef={(text: TextInput) => {
                if (this.props.textInputRef) {
                    this.props.textInputRef(text);
                }
                this.text = text;
            }} value={val} onChange={this.handleChange} error={errorText} label={label} placeholder={placeholder} multiline={true} accessibleName={accessibleName} rows={2} // 2 is enough to hint that this is a multi-line editor.
         showBorder={this.state.showMarkdownEditor} {...otherProps}/>
                {bottomNoteText && <Note>{bottomNoteText}</Note>}
                {this.state.showMarkdownEditor ? (<div className={styles.markdownControlsContainer}>
                        {showMarkdownControlsToggle}
                        <Note>
                            <ExternalLink href="MasteringMarkdown">Markdown instructions</ExternalLink>
                        </Note>
                    </div>) : (showMarkdownControlsToggle)}
            </div>);
    }
    private createButtons() {
        return (<div className={styles.iconButtonContainer}>
                {[
                this.editAction("Bold", "fa-bold", this.bold),
                this.editAction("Italic", "fa-italic", this.italic),
                this.editAction("Bulleted list", "fa-list-ul", this.bullet),
                this.editAction("Numeric list", "fa-list-ol", this.number),
                this.editAction("Picture", "fa-image", this.image),
                this.editAction("Link", "fa-link", this.link),
                this.editAction("Code", "fa-code", this.code),
                this.editAction("Quotes", "fa-quote-left", this.quote),
            ]}
            </div>);
    }
    private editAction(label: string, faIcon: string, onClick: () => void) {
        return (<Tooltip content={label} key={`tooltip_${faIcon}`}>
                <Button tabIndex={-1} className={markdownEditorButtonStyle} onClick={onClick} aria-label={label}>
                    <em className={`fa-solid ${faIcon}`} aria-hidden="true"/>
                </Button>
            </Tooltip>);
    }
    static displayName = "MarkdownEditor";
}
const markdownEditorButtonStyle = css({
    "&.MuiButton-root": {
        minWidth: "auto",
        width: "2rem",
        height: "2.25rem",
        borderRadius: "0",
        color: themeTokens.color.text.primary,
        fontSize: 14,
    },
});
const MarkdownEditorWithInputRef = (props: MarkdownEditorProps & GlobalConnectedProps) => <MarkdownEditor {...props}/>;
export const VariableLookupMarkdownEditor = withVariableLookup()(MarkdownEditorWithInputRef);
